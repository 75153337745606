/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { MultipleDropdown } from '@classapp-tech/edna';
import { InputLabel, InputContainer } from './ViewStyle';
import { __ } from '../../../../../i18n';

/**
 * @typedef {{
 *  organizations: OptionItem[];
 *  organizationOptions?: OptionItem[];
 *  setOrganizationOffset: React.Dispatch<React.SetStateAction<number>>;
 *  organizationLoading: boolean;
 *  handleOrganizationSearch: (value: string) => void;
 *  organizationSearch: string;
 *  handleOrganizationChange: (isSelected: boolean, option: OptionItem) => void;
 *  isAllSelected?: boolean;
 *  setIsAllSelected?: React.Dispatch<React.SetStateAction<boolean>>;
 *  organizationsTotalCount: number | null;
 *  handleSelectAllChange: (isSelected: boolean, values: OptionItem[]) => void;
 *  excludeOrganizationsLength?: number;
 * }} IViewUIProps
 */

export function ViewUI(
  /** @type {IViewUIProps} */ {
    organizations,
    organizationOptions = [],
    setOrganizationOffset,
    organizationLoading,
    handleOrganizationSearch,
    organizationSearch,
    handleOrganizationChange,
    isAllSelected,
    organizationsTotalCount,
    handleSelectAllChange,
    excludeOrganizationsLength,
  },
) {
  return (
    <InputContainer>
      <InputLabel>{`${__('Select the units')}*:`}</InputLabel>
      <MultipleDropdown.Simple
        onLoadMore={() =>
          setOrganizationOffset(/** @type {number} */ (prev) => prev + 10)
        }
        isLoading={organizationLoading}
        options={organizationOptions}
        values={organizations}
        placeholder={__('Select the units')}
        searchText={organizationSearch}
        setSearchText={handleOrganizationSearch}
        notFoundText={__('No results found')}
        selectedOptionsText={__('selected (plural)')}
        selectAllComponent={<div>{__('Select all')}</div>}
        hasCheckbox
        totalOfOptions={organizationsTotalCount ?? 0}
        onSelectAll={handleSelectAllChange}
        isAllSelected={isAllSelected}
        onOptionChange={(isSelected, option) =>
          handleOrganizationChange(isSelected, option)
        }
        excludedOptionsLength={excludeOrganizationsLength}
      />
    </InputContainer>
  );
}
