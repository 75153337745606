import React from 'react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../../../../i18n';

import { NetworkEntityName } from './NetworkEntityName';
import { LoaderMessage } from './LoaderMessage';
import { hasCommunicationStatusFeedback } from '../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

const styles = {
  typeIcon: {
    color: '#9696a0',
    marginRight: '0.4285712rem',
  },
  defaultTextColor: {
    color: '#000000',
    opacity: '.8',
    display: 'flex',
  },
  textbelow: {
    color: '#616161',
    fontSize: '15px',
    fontWeight: 'bold',
  },
};

/**
 * @param {{
 *  message: {
 *  statusSend: string,
 *  entity: {
 *   fullname: string,
 *   },
 *  organization: string
 *  unread: boolean,
 *  },
 *  isNetworkChild: boolean,
 * }} params
 */
export function ReceivedSection({ message, isNetworkChild, organization }) {
  if (!message?.entity) return null;

  if (isNetworkChild) {
    return <NetworkEntityName message={message} />;
  }

  if (isNetwork(organization) && hasCommunicationStatusFeedback(organization)) {
    if (
      message.statusSend !== 'DONE' &&
      message.statusSend !== null &&
      message.statusSend !== 'PARTIAL_ERROR' &&
      message.statusSend !== 'ERROR'
    ) {
      return (
        <div style={{ marginTop: '-9px' }}>
          <LoaderMessage
            statusSend={message.statusSend}
            hasCommunicationStatusFeedback={hasCommunicationStatusFeedback}
          />
        </div>
      );
    } else if (message.statusSend === 'PARTIAL_ERROR') {
      return (
        <span style={styles.textbelow}>
          <Icon
            data-name="error"
            name="circle exclamation"
            style={message.deleted ? { opacity: 0.65 } : { ...styles.typeIcon }}
          />
          {__('Fail to send: some recipients did not receive.')}
        </span>
      );
    } else if (message.statusSend === 'ERROR') {
      return (
        <span style={styles.textbelow}>
          <Icon
            data-name="error"
            name="circle exclamation"
            style={message.deleted ? { opacity: 0.65 } : { ...styles.typeIcon }}
          />
          {__('Fail to send')}
        </span>
      );
    }
  }

  return (
    <div style={styles.defaultTextColor}>
      {message?.deleted ? (
        <Icon
          data-testid="trash-icon"
          data-name="trash-alt"
          name="trash"
          style={styles.typeIcon}
        />
      ) : message?.unread != null ? (
        <Icon
          data-testid="unread-icon"
          data-name="unread-mail"
          name="envelope"
          style={styles.typeIcon}
        />
      ) : (
        <Icon
          data-testid="read-icon"
          name="envelope open outline"
          style={styles.typeIcon}
        />
      )}
      <span
        style={message?.deleted ? { opacity: 0.65, fontStyle: 'italic' } : null}
      >
        {message?.entity?.fullname}
      </span>
      {message?.deleted ? (
        <span
          style={
            message?.deleted ? { opacity: 0.65, fontStyle: 'italic' } : null
          }
        >
          &nbsp;{'·'}&nbsp;
        </span>
      ) : null}
      {message?.user && message?.user?.fullname.length > 0 && (
        <div>
          <span
            style={
              message?.deleted ? { opacity: 0.65, fontStyle: 'italic' } : null
            }
          >
            &nbsp;{'·'}&nbsp;
          </span>
          <span
            style={{
              color: '#9696a0',
              opacity: message?.deleted ? 0.65 : null,
              fontStyle: message?.deleted ? 'italic' : null,
            }}
          >
            {message?.user?.fullname}
          </span>
        </div>
      )}
    </div>
  );
}
