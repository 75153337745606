/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { __ } from '../../../../i18n';
import { Icon } from 'semantic-ui-react';
import { resendCommunication } from '../helpers/resendCommunication';
import { Loading } from '@classapp-tech/edna';
import { useSnackbar } from '../../../../components/kit/Snackbar/hooks/useSnackbar';

export function ButtonTryAgain({ client, messageId, closeModal }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await resendCommunication(client, messageId);

      if (response.data.resendMessageNetwork.error) {
        setTimeout(() => {
          closeModal();
        }, 1000);
        useSnackbar().toast({
          content: (
            <p style={{ width: '100%', fontSize: '14px', color: 'black' }}>
              {__('Another user already sent this message')}
            </p>
          ),
          type: 'WARNING',
        });
      }

      if (response.data.resendMessageNetwork.success) {
        setTimeout(() => {
          closeModal();
        }, 1000);
        useSnackbar().toast({
          content: (
            <p style={{ width: '100%', fontSize: '14px', color: 'black' }}>
              {__('The message is being sent. This action may take a few minutes')}
            </p>
          ),
          type: 'SUCCESS',
        });
      }
    } catch (error) {
      setTimeout(() => {
        closeModal();
      }, 1000);
      useSnackbar().toast({
        content: (
          <p style={{ width: '100%', fontSize: '14px', color: 'black' }}>
            {__(
              'An error occurred when trying to send the message. Try to send it again later.',
            )}
          </p>
        ),
        type: 'ERROR',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        color: '#BF2600',
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: '13px',
      }}
      type="button"
      className="btn btn-alert"
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? (
        <>
          <Loading size={10} variation="basic" />
        </>
      ) : (
        <>
          <Icon name="redo" />
          {__('Try again')}
        </>
      )}
    </button>
  );
}
