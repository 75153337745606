import React from 'react';
import { Menu } from 'semantic-ui-react';
import { __ } from '../i18n';

/**
 * @typedef {{
*   createDateFromMonth: () => Date;
*   setSelectedMonth: (month: number) => void;
*   selectedMonth: number;
*   closeMonthCalendar: () => void;
*   currentYear: number;
*   setCurrentYear: (year: number) => void;
* }} MonthCalendarProps
*/

/**
* Renders a calendar for selecting a month within a specific year.
*
* @param {MonthCalendarProps} props
* @returns {JSX.Element} The rendered month calendar component.
*/
export default function MonthCalendar({
  createDateFromMonth, 
  setSelectedMonth,
  selectedMonth,
  closeMonthCalendar,
  currentYear,
  setCurrentYear
}) {
  const monthNames = [__('jan'), __('feb'), __('mar'), __('apr'), __('may'), __('jun'), __('jul'), __('aug'), __('sep'), __('oct'), __('nov'), __('dec')];
  const setFirstLetterToUpperCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const isCurrentYear = currentYear === new Date().getFullYear()
  const isMinimumYear = currentYear === 1924

  const monthAbbreviationToIndex = []
  monthNames.forEach((month, index) => {
    monthAbbreviationToIndex[month] = index
  })

  const onPrev = () => {
    const prevYear = currentYear - 1
    setCurrentYear(prevYear)
  }

  const onNext = () => {
    const nextYear = currentYear + 1
    setCurrentYear(nextYear)
  }
  
  return (
    <div data-testid="monthCalendar">
      <header style={{ display: 'flex' }}>
        <Menu widths={6} borderless style={{border: 'none'}}>
          <Menu.Item 
            data-testid="prevYear"    
            onClick={() => onPrev()} 
            icon="arrow left"
            style={{opacity: isMinimumYear ? 0.4 : 1}} 
            disabled={isMinimumYear}
          />
          <Menu.Item className="title" style={{color: '#E5E5E5'}}>{currentYear - 1}</Menu.Item>
          <Menu.Item className="title" style={{ minWidth: '120px' }}>{currentYear}</Menu.Item>
          <Menu.Item className="title" style={{color: '#E5E5E5'}}>{currentYear + 1}</Menu.Item>
          <Menu.Item 
            data-testid="nextYear"    
            onClick={() => onNext()} 
            style={{opacity: isCurrentYear ? 0.4 : 1}} 
            disabled={isCurrentYear}
            icon="arrow right" 
          />
        </Menu>
      </header>
      <section 
       style={{ 
          display: 'grid', 
          gridTemplateColumns: '1fr 1fr 1fr 1fr', 
          rowGap: '0.5rem', 
          columnGap: '0.8rem',
          marginTop: '1rem',
        }}
      >
        {monthNames.map((month, index) => (
          <Menu.Item 
          data-testid={`monthNames-${index}`}
          className="title"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.3rem 0.9rem',
            backgroundColor: selectedMonth === index ? '#0069FF' : '#FFFFFF',
            color: selectedMonth === index ? '#FFFFFF' : '#000000',
            borderRadius: '12.5rem',
            fontSize: '0.875rem',
            cursor: selectedMonth === index ? 'default' : 'pointer',
          }} 
          key={index}
          onClick={() => {
            createDateFromMonth(currentYear, month, monthAbbreviationToIndex)
            setSelectedMonth(monthAbbreviationToIndex[month]);
            closeMonthCalendar()
          }}
          >
            {setFirstLetterToUpperCase(month)}
          </Menu.Item>
        ))}
      </section>
    </div>
  )
}
