/**
 * @param {import('@classapp-tech/edna').OptionItem[]} options
 * @param {import('@classapp-tech/edna').OptionItem} parent
 * @param {import('@classapp-tech/edna').OptionItem[] | undefined} updatedChildren
 */

export function updateMarkersOptionsWithChildren(
  options,
  parent,
  updatedChildren,
) {
  return options.map((__option) => ({
    ...__option,
    isSelected:
      __option.id === parent.id
        ? updatedChildren?.every((child) => child.isSelected)
        : __option.isSelected,
    children: __option.id === parent.id ? updatedChildren : __option.children,
  }));
}
