import React, { useEffect, useRef } from 'react';
import { ViewUI } from './ViewUI';
import { ViewModel as useViewModel } from './ViewModel';

const MAX_OPTIONS = 10;

/**
 * @param {{
 *  entityId: number | string,
 *  organizationId: number | string,
 *  markersState: import ('../../hooks/useSelectRecipientModal').markersState,
 *  setMarkersState: (state: import ('../../hooks/useSelectRecipientModal').markersState) => void,
 *  selectOrganizationsIds: string[],
 * }} param
 */
export function ViewController({
  entityId,
  organizationId,
  markersState,
  setMarkersState,
  selectOrganizationsIds = [],
}) {
  const markersStateRef = useRef(markersState);

  const {
    getOrganizationsByNetworkId,
    organizationOptions,
    organizationOffset,
    setOrganizationOffset,
    organizationSearch,
    organizationLoading,
    handleOrganizationSearch,
    organizationsTotalCount,
    handleOrganizationChange,
    handleSelectAllChange,
    loadedSelectedOrganizations,
    setLoadedSelectedOrganizations,
  } = useViewModel({
    organizationId,
    entityId,
    markersState,
    setMarkersState,
    markersStateRef,
  });

  useEffect(() => {
    markersStateRef.current = markersState;
  }, [markersState]);

  useEffect(() => {
    if (selectOrganizationsIds.length > 0)
      setMarkersState({ organizations: selectOrganizationsIds });
  }, [selectOrganizationsIds]);

  useEffect(() => {
    if (organizationId && entityId) {
      // eslint-disable-next-line func-names
      (async function () {
        await getOrganizationsByNetworkId({
          entityId,
          organizationId,
          search: organizationSearch,
          offset: organizationOffset,
          limit: MAX_OPTIONS,
        });
      })();
    }
  }, [organizationId, entityId, organizationOffset, organizationSearch]);

  useEffect(() => {
    setLoadedSelectedOrganizations(
      organizationOptions
        .filter(
          ({ id }) =>
            markersState?.organizations.includes(String(id)) ||
            markersState?.excludeOrganizations.some(
              (excludeOrg) => String(excludeOrg.id) === String(id),
            ),
        )
        .map(({ id }) => id),
    );
  }, [organizationOptions, markersState?.organizations]);

  return (
    <ViewUI
      organizations={
        markersState?.organizations.length > organizationOptions.length
          ? loadedSelectedOrganizations
          : markersState?.organizations
      }
      organizationOptions={organizationOptions}
      setOrganizationOffset={setOrganizationOffset}
      organizationLoading={organizationLoading}
      handleOrganizationSearch={handleOrganizationSearch}
      organizationSearch={organizationSearch}
      handleOrganizationChange={handleOrganizationChange}
      isAllSelected={
        markersState?.canAccessAllOrganizations ||
        !!loadedSelectedOrganizations.length
      }
      setIsAllSelected={(isChecked) =>
        setMarkersState({ canAccessAllOrganizations: isChecked })
      }
      organizationsTotalCount={organizationsTotalCount}
      handleSelectAllChange={handleSelectAllChange}
      excludeOrganizationsLength={
        markersState?.canAccessAllOrganizations
          ? markersState?.excludeOrganizations?.length
          : organizationsTotalCount - markersState?.organizations.length
      }
    />
  );
}
