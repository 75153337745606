import React from 'react';
import { AvatarSended } from '../AvatarSended';
import { render } from '../../../../../newRender';
import { isNetwork } from '../../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { hasCommunicationStatusFeedback } from '../../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';

jest.mock(
  '../../../../../app/Domain/UseCases/Unleash/validations/IsNetwork',
  () => ({
    isNetwork: jest.fn(),
  }),
);

jest.mock(
  '../../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback',
  () => ({
    hasCommunicationStatusFeedback: jest.fn(),
  }),
);

describe('AvatarSended', () => {
  it('should render the component if is not isNetwork and not hasCommunicationStatusFeedback', () => {
    const message = {
      toEntity: {
        picture: {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          uri: 'https://example.com/picture.jpg',
        },
      },
    };
    const organization = {
      picture: {
        uri: 'https://example.com/picture.jpg',
      },
    };
    const { container } = render(
      <AvatarSended
        message={message}
        organization={organization}
        isOrganizationPage={false}
      />,
    );
    expect(container).toBeDefined();
  });

  it('should render the component if isNetwork and hasCommunicationStatusFeedback are true', () => {
    isNetwork.mockReturnValue(true);
    hasCommunicationStatusFeedback.mockReturnValue(true);

    const message = {
      toEntity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
        fullname: 'John Doe',
        status: 1,
      },
      recipientsCount: 1,
      statusSend: 'DONE',
      unread: null,
      deleted: false,
      notifications: {
        totalCount: 0,
      },
    };

    const organization = {
      picture: {
        uri: 'https://example.com/organization-picture.jpg',
      },
    };

    const { container } = render(
      <AvatarSended message={message} organization={organization} />,
    );

    expect(container).toBeDefined();
  });
});
