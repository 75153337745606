import { setAllChildrenSelected } from '../../helpers/setAllChildrenSelected';

describe('setAllChildrenSelected', () => {
  it('should update options selection with children', () => {
    const options = [
      {
        id: 1,
        content: 'Marker 1',
        isSelected: false,
        children: [
          {
            id: 3,
            content: 'Marker 3',
            isSelected: false,
          },
        ],
      },
      {
        id: 2,
        content: 'Marker 2',
        isSelected: false,
        children: [
          {
            id: 3,
            content: 'Marker 4',
            isSelected: false,
          },
        ],
      },
    ];

    const result = setAllChildrenSelected(options, true);

    expect(result).toEqual([
      {
        children: [
          {
            content: 'Marker 3',
            id: 3,
            isSelected: true,
          },
        ],
        content: 'Marker 1',
        id: 1,
        isSelected: true,
      },
      {
        children: [
          {
            content: 'Marker 4',
            id: 3,
            isSelected: true,
          },
        ],
        content: 'Marker 2',
        id: 2,
        isSelected: true,
      },
    ]);
  });

  it('should update options selection without children', () => {
    const options = [
      {
        id: 1,
        content: 'Marker 1',
        isSelected: false,
      },
      {
        id: 2,
        content: 'Marker 2',
        isSelected: false,
      },
    ];

    const result = setAllChildrenSelected(options, true);

    expect(result).toEqual([
      {
        content: 'Marker 1',
        id: 1,
        isSelected: true,
      },
      {
        content: 'Marker 2',
        id: 2,
        isSelected: true,
      },
    ]);
  });
});
