import React from 'react';
import { HeaderEntity } from '../HeaderEntity';
import { render } from '../../../../../newRender';

describe('HeaderEntity', () => {
  it('should render the component', () => {
    const message = {
      toEntity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
      },
    };

    const { container } = render(<HeaderEntity message={message} />);
    expect(container).toBeDefined();
  });
});
