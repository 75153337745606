import { updateChildrenSelectionByParent } from '../../helpers/updateChildrenSelectionByParent';

describe('updateChildrenSelectionByParent', () => {
  it('should update children selection', () => {
    const children = [
      {
        id: 1,
        content: 'Marker 1',
        isSelected: false,
      },
      {
        id: 2,
        content: 'Marker 2',
        isSelected: false,
      },
    ];
    const option = {
      id: 1,
      content: 'Marker 1',
      isSelected: false,
    };
    const isSelected = true;

    const result = updateChildrenSelectionByParent(
      children,
      option,
      isSelected,
    );

    expect(result).toEqual([
      {
        id: 1,
        content: 'Marker 1',
        isSelected: true,
      },
      {
        id: 2,
        content: 'Marker 2',
        isSelected: false,
      },
    ]);
  });
});
