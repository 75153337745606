/**
 * @typedef {{
 *  id: number | string;
 *  name: string;
 *  markers: import('@classapp-tech/edna').OptionItem[];
 * }} marker
 */

/**
 * @param {import('../../../hooks/useMarkersState').markersState} markersState
 * @param {marker} marker
 */

export function getChildrenFromMarker(marker, markersState) {
  if (!marker?.markers || !marker?.markers?.length) return [];

  return marker?.markers?.map((_marker) => ({
    id: _marker.id,
    content: _marker.name,
    isSelected: !!(
      markersState.canAccessAllMarkers ||
      markersState?.markers?.some((item) => item === _marker.id)
    ),
  }));
}
