import React from 'react';
import { ButtonTryAgain } from '../TryAgainButton';
import { render, fireEvent, waitFor, screen } from '../../../../../newRender';
import { resendCommunication } from '../../helpers/resendCommunication';
import { useSnackbar } from '../../../../../components/kit/Snackbar/hooks/useSnackbar';

jest.mock('../../helpers/resendCommunication');
jest.mock('../../../../../components/kit/Snackbar/hooks/useSnackbar');

describe('ButtonTryAgain', () => {
  let closeModalMock;
  let clientMock;
  let messageIdMock;
  let snackbarMock;

  beforeEach(() => {
    closeModalMock = jest.fn();
    clientMock = {};
    messageIdMock = 'test-message-id';
    snackbarMock = { toast: jest.fn() };
    useSnackbar.mockReturnValue(snackbarMock);

    resendCommunication.mockResolvedValue({
      data: {
        resendMessageNetwork: {
          success: true,
        },
      },
    });
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  it('should call closeModal after successfully resending the message', async () => {
    render(
      <ButtonTryAgain
        client={clientMock}
        messageId={messageIdMock}
        closeModal={closeModalMock}
      />,
    );

    const button = screen.getByRole('button');
    fireEvent.click(button);

    const loadingElement = document.querySelector('.StyledDefault--1fmbtkw');
    expect(loadingElement).toBeInTheDocument();

    await waitFor(() => expect(closeModalMock).toHaveBeenCalledTimes(1), {
      timeout: 1500,
    });
  });

  it('should not call closeModal after failing to resend the message', async () => {
    resendCommunication.mockResolvedValue({
      data: {
        resendMessageNetwork: {
          success: false,
        },
      },
    });

    render(
      <ButtonTryAgain
        client={clientMock}
        messageId={messageIdMock}
        closeModal={closeModalMock}
      />,
    );

    const button = screen.getByRole('button');
    fireEvent.click(button);

    await waitFor(() => expect(closeModalMock).not.toHaveBeenCalled());
  });

  it('should render the component', () => {
    const { container } = render(<ButtonTryAgain />);
    expect(container).toBeDefined();
  });

  it('should show a warning if another user already sent the message', async () => {
    resendCommunication.mockResolvedValue({
      data: {
        resendMessageNetwork: {
          error: true,
        },
      },
    });

    render(
      <ButtonTryAgain
        client={clientMock}
        messageId={messageIdMock}
        closeModal={closeModalMock}
      />,
    );

    const button = screen.getByRole('button');
    fireEvent.click(button);

    await waitFor(() => expect(closeModalMock).toHaveBeenCalledTimes(1), {
      timeout: 1500,
    });

    expect(snackbarMock.toast).toHaveBeenCalledWith(
      expect.objectContaining({
        content: expect.objectContaining({
          props: expect.objectContaining({
            children: expect.stringContaining(
              'Another user already sent this message',
            ),
          }),
        }),
        type: 'WARNING',
      }),
    );
  });
});
