/* eslint-disable sonarjs/no-duplicate-string */
import { getMarkers } from '../../helpers/getMarkers';

describe('getMarkers', () => {
  it('should return markers with option selected', () => {
    const isSelected = true;
    const option = {
      id: 1,
      content: 'option',
      children: [
        { id: 2, content: 'child content 2' },
        { id: 3, content: 'child content 3' },
      ],
    };
    const markersState = {
      markers: [],
    };

    const result = getMarkers(isSelected, option, markersState);

    expect(result).toEqual(['2', '3']);
  });

  it('should return markers without option selected', () => {
    const isSelected = false;
    const option = {
      id: 1,
      name: 'option',
      content: 'option content',
      children: [
        { id: 2, content: 'child content 1' },
        { id: 3, content: 'child content 2' },
      ],
    };
    const markersState = {
      markers: ['2', '3'],
    };

    const result = getMarkers(isSelected, option, markersState);

    expect(result).toEqual([]);
  });
});
