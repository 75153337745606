import React from 'react';
import { render } from '../../../../../../newRender';
import { Option } from './Option';

describe('<Option />', () => {
  let props;

  beforeEach(() => {
    props = {
      id: '1',
      fullname: 'Organization Acme',
      logo: {
        uri: 'https://example.com/logo.png',
      },
    };
  });

  it('should not newRender component when fullname is not provided', () => {
    props.fullname = '';
    const { container } = render(<Option {...props} />);
    expect(container.firstChild).toBeNull();
  });

  it('should newRender component when fullname is lower than 120 characters', () => {
    const { queryByText } = render(<Option {...props} />);
    expect(queryByText(props.fullname)).toBeInTheDocument();
  });

  it('should newRender component without logo', () => {
    props.logo = null;
    const { queryByText } = render(<Option {...props} />);
    expect(queryByText('OA')).toBeInTheDocument();
  });

  it('should newRender component when fullname is upper than 120 characters', () => {
    const largeFullname = 'a'.repeat(120);
    props.fullname = 'a'.repeat(150);
    const { queryByText } = render(<Option {...props} />);
    expect(queryByText(`${largeFullname}...`)).toBeInTheDocument();
  });
});
