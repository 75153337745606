/**
 * @param {boolean} isSelected
 * @param {import('@classapp-tech/edna').OptionItem} option
 * @param {import('../../../hooks/useMarkersState').markersState} markersState
 */

export function getMarkers(isSelected, option, markersState) {
  if (isSelected) {
    return [
      ...(markersState.markers ?? []),
      ...option.children.map((child) => String(child.id)),
    ];
  }

  const childrenIds = option.children.map((child) => String(child.id));

  return markersState?.markers?.filter(
    (item) => !childrenIds.includes(String(item)),
  );
}
