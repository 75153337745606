import React from 'react';
import { __ } from '../../../../../i18n';
import Form from '../../../../../components/ui/Form';
import { ViewController as MarkersDropdown } from '../MarkersDropdown/ViewController';
import { ViewController as UnitsDropdown } from '../UnitsDropdown/ViewController';
import { Icon, Popup } from 'semantic-ui-react';

import { defaultMarkersState } from './helpers/defaultMarkersState';

/**
 * @param {{
 * organizationId: number | string,
 * entityId: number | string,
 * communicationToAll: boolean,
 * oncommunicationToAllChange: () => null,
 * isAdmin: boolean,
 * setMarkersState: (state: import ('../../hooks/useSelectRecipientModal').markersState) => void,
 * markersState: import ('../../hooks/useSelectRecipientModal').markersState,
 * selectedMarkersIds: string[],
 * selectOrganizationsIds: string[],
 * }} props
 */

export function Container({
  organizationId,
  entityId,
  communicationToAll,
  oncommunicationToAllChange,
  setMarkersState,
  markersState,
  isAdmin = false,
  selectedMarkersIds,
  errors,
  selectOrganizationsIds,
}) {
  const styles = {
    section: {
      marginBottom: '30px',
    },
    subTitle: {
      marginBottom: '30px',
    },
    subTitleText: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    popup: {
      fontWeight: 300,
      fontSize: '20px',
      opacity: '.6',
      marginLeft: '0.5rem',
      marginBottom: '0.25rem',
    },
  };

  const messagePermission = (
    <div>
      <p style={{ fontSize: '1rem' }}>
        {__(
          'Grants permission to send messages to any groups, students, or staff of the institution.',
        )}
      </p>
    </div>
  );

  const handleAllowComunicationChange = () => {
    setMarkersState(
      defaultMarkersState({
        defaultMarkers: selectedMarkersIds,
        defaultOrganizations: selectOrganizationsIds,
      }),
    );
    oncommunicationToAllChange();
  };

  return (
    <div style={styles.section}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{__('Permissions')}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Form.Checkbox
          label={__('Allow communication with all users')}
          name="allowComunication"
          bold={false}
          checked={isAdmin || communicationToAll}
          onClick={handleAllowComunicationChange}
          style={{ marginLeft: '10px' }}
          disabled={isAdmin}
        />
        <Popup
          trigger={
            // eslint-disable-next-line prettier/prettier
            <Icon
              style={{ ...styles.popup }}
              name="info circle"
              color="grey"
            />
          }
          content={<span>{messagePermission}</span>}
          hideOnScroll
          basic
        />
      </div>
      {!isAdmin && !communicationToAll && (
        <div style={{ marginTop: '2rem' }}>
          <UnitsDropdown
            organizationId={organizationId}
            entityId={entityId}
            setMarkersState={setMarkersState}
            markersState={markersState}
            selectOrganizationsIds={selectOrganizationsIds}
          />
          {errors &&
            errors.markersState &&
            errors.markersState !== 'Error' &&
            !markersState.organizations.length && (
            <div className="error-warning-red">
              <Icon data-value="error" name="exclamation circle" />
              {errors.markersState}
            </div>
          )}
          <MarkersDropdown
            selectedMarkersIds={selectedMarkersIds}
            isDisabled={false}
            entityId={entityId}
            organizationId={organizationId}
            setMarkersState={setMarkersState}
            markersState={markersState}
          />
          {errors &&
            errors.markersState &&
            errors.markersState !== 'Error' &&
            !markersState.markers.length && (
            <div className="error-warning-red">
              <Icon data-value="error" name="exclamation circle" />
              {errors.markersState}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
