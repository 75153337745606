/**
 * @param {import('@classapp-tech/edna').OptionItem[]} options
 * @param {import('@classapp-tech/edna').OptionItem} option
 * @param {boolean} isSelected
 */

export function updateOptionSelection(options, option, isSelected) {
  return options.map((__option) => ({
    ...__option,
    isSelected: option.id === __option.id ? isSelected : __option.isSelected,
    children: __option.children?.map((child) => ({
      ...child,
      isSelected: option.id === __option.id ? isSelected : child.isSelected,
    })),
  }));
}
