/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { renderHook, act } from '@testing-library/react-hooks/dom';
import { waitFor } from '@testing-library/react';
import { ViewModel } from '../ViewModel';
import * as getOrganizationsByNetworkIdUseCase from '../../../../../Domain/UseCases/Permissions/getOrganizationsByNetworkIdUseCase';

import { Option } from '../components/Option';

describe('ViewModel', () => {
  let props;
  beforeEach(() => {
    jest.clearAllMocks();

    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockResolvedValue({
        data: {
          node: {
            organizations: {
              nodes: [
                {
                  id: 1,
                  fullname: 'Organization 1',
                  logo: {
                    uri: 'logo.png',
                  },
                },
              ],
              totalCount: 1,
            },
          },
        },
      });

    props = {
      organizationId: 1,
      entityId: 1,
      markersState: {
        organizations: [],
        canAccessAllOrganizations: false,
        markers: [],
        canAccessAllMarkers: false,
        excludeMarkers: [],
        excludeOrganizations: [],
        markersTotalCount: 0,
        organizationsTotalCount: 0,
      },
      setMarkersState: jest.fn(),
      markersStateRef: {
        current: {
          organizations: [],
          canAccessAllOrganizations: false,
          markers: [],
          canAccessAllMarkers: false,
          excludeMarkers: [],
          excludeOrganizations: [],
          markersTotalCount: 0,
          organizationsTotalCount: 0,
        },
      },
      selectOrganizationsIds: [],
    };
  });

  it('should be able to get organizations when variables is provided', async () => {
    const variables = {
      organizationId: 1,
      search: '',
      offset: 0,
    };

    const QueryMock = jest.fn().mockResolvedValue({
      data: {
        node: {
          organizations: {
            nodes: [
              {
                id: 1,
                fullname: 'Organization 1',
                logo: {
                  uri: 'logo.png',
                },
              },
            ],
          },
        },
      },
    });
    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockImplementation(QueryMock);
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      await result.current.getOrganizationsByNetworkId(variables);
    });

    expect(result.current.organizationOptions).toStrictEqual([
      {
        content: (
          <Option fullname="Organization 1" id={1} logo={{ uri: 'logo.png' }} />
        ),
        id: 1,
        isSelected: false,
      },
    ]);
  });

  it('should be able to get organizations when variables is provided and has select all option', async () => {
    const variables = {
      organizationId: 1,
      search: '',
      offset: 0,
    };

    const QueryMock = jest.fn().mockResolvedValue({
      data: {
        node: {
          organizations: {
            nodes: [
              {
                id: 1,
                fullname: 'Organization 1',
                logo: {
                  uri: 'logo.png',
                },
              },
            ],
          },
        },
      },
    });
    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockImplementation(QueryMock);
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      await result.current.getOrganizationsByNetworkId(variables);
    });

    expect(result.current.organizationOptions).toStrictEqual([
      {
        content: (
          <Option
            fullname="Organization 1"
            id={1}
            logo={{
              uri: 'logo.png',
            }}
          />
        ),
        id: 1,
        isSelected: false,
      },
    ]);
  });

  it('should be able to return empty when has error in get organizations', async () => {
    const variables = {
      organizationId: 1,
      search: '',
      offset: 0,
    };

    const QueryMock = jest.fn().mockResolvedValue({
      data: null,
      errors: ['error'],
    });
    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockImplementation(QueryMock);
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      await result.current.getOrganizationsByNetworkId(variables);
    });

    expect(result.current.organizationOptions).toStrictEqual([]);
  });

  it('should be able to return empty when has no data in get organizations', async () => {
    const variables = {
      organizationId: 1,
      search: '',
      offset: 0,
    };

    const QueryMock = jest.fn().mockResolvedValue({
      data: null,
      errors: null,
    });
    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockImplementation(QueryMock);
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      await result.current.getOrganizationsByNetworkId(variables);
    });

    expect(result.current.organizationOptions).toStrictEqual([]);
  });

  it('should debounce the organizationDebounce function calls', async () => {
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      result.current.organizationDebounce('test-search', 123);
    });

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 2000));

    expect(
      getOrganizationsByNetworkIdUseCase.getOrganizationsByNetworkIdUseCase,
    ).toHaveBeenCalledWith({
      organizationId: 123,
      limit: 10,
      offset: 0,
      search: 'test-search',
    });
  });

  it('should not call getOrganizationsByNetworkIdUseCase when totalCount has been reached', async () => {
    const variables = {
      organizationId: 1,
      search: '',
      offset: 0,
    };

    const QueryMock = jest.fn().mockResolvedValue({
      data: {
        node: {
          organizations: {
            nodes: [
              {
                id: 1,
                fullname: 'Organization 1',
                logo: {
                  uri: 'logo.png',
                },
              },
            ],
            totalCount: 1,
          },
        },
      },
    });
    jest
      .spyOn(
        getOrganizationsByNetworkIdUseCase,
        'getOrganizationsByNetworkIdUseCase',
      )
      .mockImplementation(QueryMock);
    const { result } = renderHook(() => ViewModel(props));

    act(() => {
      result.current.setOrganizationsTotalCount(1);
      result.current.setOrganizationOptions([
        {
          content: (
            <Option
              fullname="Organization 1"
              id={1}
              logo={{
                uri: 'logo.png',
              }}
            />
          ),
          id: 1,
          isSelected: false,
        },
      ]);
    });

    await act(async () => {
      await result.current.getOrganizationsByNetworkId(variables);
    });

    // eslint-disable-next-line max-len
    expect(
      getOrganizationsByNetworkIdUseCase.getOrganizationsByNetworkIdUseCase,
    ).not.toHaveBeenCalled();
  });

  describe('handleSelectAllChange', () => {
    it('should be able to call select all function with true', async () => {
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.setOrganizationOptions([
          {
            content: (
              <Option
                fullname="Organization 1"
                id={1}
                logo={{
                  uri: 'logo.png',
                }}
              />
            ),
            id: 1,
            isSelected: false,
          },
          {
            content: (
              <Option
                fullname="Organization 2"
                id={2}
                logo={{
                  uri: 'logo.png',
                }}
              />
            ),
            id: 2,
            isSelected: false,
          },
        ]);
      });

      await act(async () => {
        await result.current.handleSelectAllChange(true);
      });

      waitFor(() => {
        expect(props.markersState.setCanAccessAllOrganizations).toBeCalledWith(true);
        expect(props.markersState.setOrganizations).toBeCalled();
      });
    });

    it('should be able to call select all function with false', async () => {
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.setOrganizationOptions([
          {
            content: (
              <Option
                fullname="Organization 1"
                id={1}
                logo={{
                  uri: 'logo.png',
                }}
              />
            ),
            id: 1,
            isSelected: false,
          },
          {
            content: (
              <Option
                fullname="Organization 2"
                id={2}
                logo={{
                  uri: 'logo.png',
                }}
              />
            ),
            id: 2,
            isSelected: false,
          },
        ]);
      });

      await act(async () => {
        await result.current.handleSelectAllChange(false);
      });

      waitFor(() => {
        expect(props.markersState.setCanAccessAllOrganizations).toBeCalledWith(false);
        expect(props.markersState.setOrganizations).toBeCalled();
        expect(props.markersState.setExcludeOrganizations).toBeCalledWith([]);
      });
    });
  });

  describe('handleOrganizationSearch', () => {
    it('should be able to call handleOrganizationSearch when value is provided', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationSearch('test');
      });

      expect(result.current.organizationSearch).toBe('test');
      expect(result.current.organizationLoading).toBe(true);
      expect(result.current.organizationOffset).toBe(0);
    });

    it('should be able to call handleOrganizationSearch when value is not provided', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationSearch('');
      });

      expect(result.current.organizationSearch).toBe('');
    });
  });

  describe('handleOrganizationChange', () => {
    it('should be able to call handleOrganizationChange when isSelected is true', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationChange(true, {
          content: (
            <Option
              fullname="Organization 1"
              id={1}
              logo={{
                uri: 'logo.png',
              }}
            />
          ),
          id: 1,
          isSelected: false,
        });
      });

      expect(props.setMarkersState).toBeCalled();
    });

    it('should be able to call handleOrganizationChange when isSelected is false', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationChange(false, {
          content: (
            <Option
              fullname="Organization 1"
              id={1}
              logo={{
                uri: 'logo.png',
              }}
            />
          ),
          id: 1,
          isSelected: false,
        });
      });

      expect(props.setMarkersState).toBeCalled();
    });

    it('should be able to call handleOrganizationChange when isSelected is true and canAccessAllOrganizations is true', () => {
      props.markersState.canAccessAllOrganizations = true;
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationChange(true, {
          content: (
            <Option
              fullname="Organization 1"
              id={1}
              logo={{
                uri: 'logo.png',
              }}
            />
          ),
          id: 1,
          isSelected: false,
        });
      });

      expect(props.setMarkersState).toBeCalled();
    });

    it('should be able to call handleOrganizationChange when isSelected is false and canAccessAllOrganizations is true', () => {
      props.markersState.canAccessAllOrganizations = true;
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleOrganizationChange(false, {
          content: (
            <Option
              fullname="Organization 1"
              id={1}
              logo={{
                uri: 'logo.png',
              }}
            />
          ),
          id: 1,
          isSelected: false,
        });
      });

      expect(props.setMarkersState).toBeCalled();
    });
  });

  it('should be able to call updateOptionsFromGroups function', async () => {
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      result.current.updateOptionsFromGroups(
        {
          id: 1,
          content: 'Group 1',
          isSelected: false,
        },
        true,
      );
    });

    waitFor(() => {
      expect(result.current.updateOptionsFromGroups).toReturnWith({
        id: 1,
        content: 'Group 1',
        isSelected: true,
      });
    });
  });
});
