import { getOptions } from '../../helpers/getOptions';

describe('getOptions', () => {
  let markersState;

  beforeEach(() => {
    jest.clearAllMocks();

    markersState = {
      canAccessAllMarkers: false,
      markers: [
        {
          id: 1,
          name: 'Option 1',
          isSelected: false,
          children: [
            {
              id: 2,
              name: 'Marker 1',
              isSelected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Option 2',
          isSelected: false,
          children: [
            {
              id: 3,
              name: 'Marker 2',
              isSelected: undefined,
            },
          ],
        },
      ],
    };
  });
  it('should return an array of options', () => {
    const selectedMarkersIds = ['1', '2'];

    const options = getOptions(
      [
        {
          id: 1,
          name: 'Option 1',
          markers: [
            {
              id: 2,
              name: 'Marker 1',
              isSelected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Option 2',
          markers: [
            {
              id: 3,
              name: 'Marker 2',
              isSelected: undefined,
            },
          ],
        },
      ],
      markersState,
      selectedMarkersIds,
    );

    expect(options).toEqual(
      [
        {
          id: 1,
          content: 'Option 1',
          isSelected: false,
          children: [
            {
              id: 2,
              content: 'Marker 1',
              isSelected: false,
            },
          ],
        },
        {
          id: 2,
          content: 'Option 2',
          isSelected: false,
          children: [
            {
              id: 3,
              content: 'Marker 2',
              isSelected: false,
            },
          ],
        },
      ],
      markersState,
    );
  });

  it('should return an empty array when options is undefined', () => {
    const options = getOptions([], markersState, []);

    expect(options).toEqual([]);
  });
});
