import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Avatar } from '@classapp-tech/edna';
import { Container } from './Option.styles';

/**
 * @typedef {{
 *    id: number | string;
 *    fullname: string;
 *    logo: {
 *      uri: string;
 *    },
 * }} OptionProps
 */

export function Option(/** @type {OptionProps} */ { id, fullname, logo }) {
  if (!fullname) return null;

  return (
    <Container data-cy={`option-row-${id}`}>
      <Avatar
        square={false}
        style={{ minWidth: '2rem' }}
        name={fullname}
        src={logo?.uri}
      />
      {fullname.length > 120 ? (
        <Popup
          trigger={<span>{`${fullname.slice(0, 120)}...`}</span>}
          content={fullname}
        />
      ) : (
        <span>{fullname}</span>
      )}
    </Container>
  );
}
