import { ViewModel } from '../ViewModel';
import * as getMarkersByOrganizationIdUseCase from '../../../../../Domain/UseCases/Permissions/GetMarkersByOrganizationId';
import { renderHook, act } from '@testing-library/react-hooks/dom';

describe('ViewModel', () => {
  let props;
  beforeEach(() => {
    jest.clearAllMocks();

    jest
      .spyOn(
        getMarkersByOrganizationIdUseCase,
        'getMarkersByOrganizationIdUseCase',
      )
      .mockResolvedValue({
        data: {
          node: {
            markers: {
              totalCount: 4,
            },
            markerCollections: {
              nodes: [
                {
                  id: 1,
                  name: 'Marker 1',
                  markers: [],
                },
              ],
              totalCount: 1,
            },
          },
        },
      });

    props = {
      organizationId: 1,
      entityId: 1,
      markersState: {
        markers: [],
        canAccessAllMarkers: false,
        excludeMarkers: [],
      },
      setMarkersState: jest.fn(),
      selectedMarkersIds: [],
    };
  });

  describe('getMarkersByOrganizationId', () => {
    it('should be able to get markers when variables is provided', async () => {
      const variables = {
        organizationId: 1,
        search: '',
        offset: 0,
      };

      const QueryMock = jest.fn().mockResolvedValue({
        data: {
          node: {
            markerCollections: {
              nodes: [
                {
                  id: 1,
                  name: 'Marker 1',
                  markers: [],
                },
              ],
            },
          },
        },
      });
      jest
        .spyOn(
          getMarkersByOrganizationIdUseCase,
          'getMarkersByOrganizationIdUseCase',
        )
        .mockImplementation(QueryMock);
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.getMarkersByOrganizationId(variables);
      });

      expect(result.current.markersOptions).toStrictEqual([
        {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
        },
      ]);
    });

    it('should be able to get markers when variables is provided and has select all option', async () => {
      const variables = {
        organizationId: 1,
        search: '',
        offset: 0,
      };

      const QueryMock = jest.fn().mockResolvedValue({
        data: {
          node: {
            markers: {
              totalCount: 4,
            },
            markerCollections: {
              nodes: [
                {
                  id: 1,
                  name: 'Marker 1',
                  markers: [],
                },
              ],
            },
          },
        },
      });
      jest
        .spyOn(
          getMarkersByOrganizationIdUseCase,
          'getMarkersByOrganizationIdUseCase',
        )
        .mockImplementation(QueryMock);
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.getMarkersByOrganizationId(variables);
      });

      expect(result.current.markersOptions).toStrictEqual([
        {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
        },
      ]);
    });

    it('should be able to return empty when has error in get organizations', async () => {
      const variables = {
        organizationId: 1,
        search: '',
        offset: 0,
      };

      const QueryMock = jest.fn().mockResolvedValue({
        data: null,
        errors: ['error'],
      });
      jest
        .spyOn(
          getMarkersByOrganizationIdUseCase,
          'getMarkersByOrganizationIdUseCase',
        )
        .mockImplementation(QueryMock);
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.getMarkersByOrganizationId(variables);
      });

      expect(result.current.markersOptions).toStrictEqual([]);
    });

    it('should be able to return empty when has no data in get markers', async () => {
      const variables = {
        organizationId: 1,
        search: '',
        offset: 0,
      };

      const QueryMock = jest.fn().mockResolvedValue({
        data: null,
        errors: null,
      });
      jest
        .spyOn(
          getMarkersByOrganizationIdUseCase,
          'getMarkersByOrganizationIdUseCase',
        )
        .mockImplementation(QueryMock);
      const { result } = renderHook(() => ViewModel(props));
      await act(async () => {
        await result.current.getMarkersByOrganizationId(variables);
      });

      expect(result.current.markersOptions).toStrictEqual([]);
    });

    it('should not call getMarkersByOrganizationId when markersTotalCount and markersOptions are set', async () => {
      const variables = {
        organizationId: 1,
        search: '',
        offset: 0,
      };

      const QueryMock = jest.fn().mockResolvedValue({
        data: {
          node: {
            markerCollections: {
              nodes: [
                {
                  id: 1,
                  name: 'Marker 1',
                  markers: [],
                },
              ],
              totalCount: 1,
            },
          },
        },
      });
      jest
        .spyOn(
          getMarkersByOrganizationIdUseCase,
          'getMarkersByOrganizationIdUseCase',
        )
        .mockImplementation(QueryMock);
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.setMarkersTotalCount(1);
        result.current.setMarkersOptions([
          {
            id: 1,
            children: [],
            content: 'Marker 1',
          },
        ]);
      });

      await act(async () => {
        await result.current.getMarkersByOrganizationId(variables);
      });

      expect(result.current.markersOptions).toStrictEqual([
        {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
        },
      ]);
    });
  });

  it('should debounce the markerDebounce function calls', async () => {
    const { result } = renderHook(() => ViewModel(props));
    await act(async () => {
      result.current.markerDebounce('test-search', 123);
    });

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 2000));

    expect(
      getMarkersByOrganizationIdUseCase.getMarkersByOrganizationIdUseCase,
    ).toHaveBeenCalledWith({
      organizationId: 123,
      limit: 10,
      offset: 0,
      search: 'test-search',
    });
  });

  describe('given handleSelectAllChange', () => {
    it('should be able to call handleSelectAllChange when is selected is true', () => {
      props.markersState = [
        {
          id: 1,
          children: [],
          content: 'Marker 1',
          isSelected: false,
        },
        {
          id: 2,
          children: [],
          content: 'Marker 2',
          isSelected: false,
        },
      ];

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleSelectAllChange(true);
      });

      expect(props.setMarkersState).toHaveBeenCalledWith({
        canAccessAllMarkers: true,
        markers: [],
        excludeMarkers: [],
      });
    });

    it('should be able to return true when is selected is false but it has more than one marker', () => {
      props.markersState = [
        {
          id: 1,
          children: [],
          content: 'Marker 1',
          isSelected: false,
        },
        {
          id: 2,
          children: [],
          content: 'Marker 2',
          isSelected: false,
        },
      ];

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleSelectAllChange(false);
      });

      expect(props.setMarkersState).toHaveBeenCalledWith({
        canAccessAllMarkers: false,
        markers: [],
        excludeMarkers: [],
      });
    });
  });

  describe('given handleMarkerSearch', () => {
    it('should test handleMarkerSearch function without organization id', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleMarkerSearch('test');
      });

      expect(result.current.markerSearch).toBe('test');
    });

    it('should test handleMarkerSearch function when search is equal marker search', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleMarkerSearch('');
      });

      expect(result.current.markerSearch).toBe('');
    });

    it('should test handleMarkerSearch function when props is provided correctly', () => {
      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleMarkerSearch('search');
      });

      expect(result.current.markerSearch).toBe('search');
      expect(result.current.markerLoading).toBe(true);
      expect(result.current.markerOffset).toBe(0);
    });
  });

  describe('given onChildrenChange', () => {
    it('should test onChildrenChange function', () => {
      props.markersState.markers = [
        {
          id: 1,
          children: [],
          content: 'Marker 1',
          isSelected: false,
        },
        {
          id: 2,
          children: [],
          content: 'Marker 2',
          isSelected: false,
        },
      ];

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.onChildrenChange(
          true,
          {
            id: 1,
            content: 'Marker 1',
            isSelected: false,
          },
          {
            id: 1,
            content: 'Marker 1',
            isSelected: false,
          },
        );
      });

      expect(props.markersState).toStrictEqual({
        excludeMarkers: [],
        canAccessAllMarkers: false,
        markers: [
          { children: [], content: 'Marker 1', id: 1, isSelected: false },
          { children: [], content: 'Marker 2', id: 2, isSelected: false },
        ],
      });
      expect(props.markersState.canAccessAllMarkers).toBe(false);
      expect(props.setMarkersState).toHaveBeenCalled();
    });

    it('should test onChildrenChange function when options is not selected', () => {
      props.markersState.markers = [];

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.onChildrenChange(
          true,
          {
            id: 1,
            content: 'Marker 1',
            isSelected: false,
          },
          {
            id: 1,
            content: 'Marker 1',
            isSelected: false,
          },
        );
      });

      expect(props.markersState.markers).toStrictEqual([]);
    });
  });

  describe('given handleMarkerChange', () => {
    it('should test handleMarkerChange function', () => {
      props.markersState.markers = ['2'];
      props.markersState.isAllMarkers = true;

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleMarkerChange(true, {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
          children: [],
        });
      });

      expect(props.markersState.markers).toStrictEqual(['2']);
    });

    it('should test handleMarkerChange function when options is not selected', () => {
      props.markersState.markers = [
        {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
          children: [
            {
              id: 2,
              content: 'Marker 2',
              isSelected: false,
            },
          ],
        },
      ];

      props.markersState.canAccessAllMarkers = false;

      const { result } = renderHook(() => ViewModel(props));

      act(() => {
        result.current.handleMarkerChange(false, {
          id: 2,
          content: 'Marker 2',
          isSelected: false,
          children: [
            {
              id: 2,
              content: 'Marker 2',
              isSelected: false,
            },
          ],
        });
      });

      expect(props.markersState.markers).toStrictEqual([
        {
          id: 1,
          content: 'Marker 1',
          isSelected: false,
          children: [
            {
              content: 'Marker 2',
              id: 2,
              isSelected: false,
            },
          ],
        },
      ]);
    });
  });
});
