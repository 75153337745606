import React from 'react';

// Libraries
import { Dropdown, Icon, Menu, Label } from 'semantic-ui-react';
import moment from 'moment';

// Components
import Tag from './Tag';
import MultiSelect from './ui/MultiSelect';
import Calendar from './Calendar';
import ColoredCheckbox from './ColoredCheckbox';
import MultipleAutosuggest from './MultipleAutosuggest';

// Others
import { __ } from '../i18n';
import { renderLongTextRaw } from '../utils';

/**
 * Action Bar to be rendered over a Table component
 * @search (optional) object with search settings, where the object is {
 *     placeholder: string,
 *     value: string,
 *     fn: callback function
 *   }
 * @order (optional) object with order settings, where the object is {
 *     options: array of strings,
 *     value: string,
 *     fn: callback function
 *   }
 * @simpleFilters (optional) boolean or array of objects to allow table filtering one by one. In case of an array, the object may be {
 *     key: string,
 *     placeholder: string,
 *     removeButton: boolean (indicates if you want a remove button on dropdown),
 *     removeButtonText: string (the text on the remove button on dropdown),
 *     multipleTags: boolean (indicates if this dropdown has multiple select options),
 *     options: array of strings,
 *     fn: callback function for the api order
 *   }
 * @filters (optional) object with filter settings, where the object is {
 *     key: string,
 *     placeholder: string,
 *     options: array of objects, where the object is { selected: boolean, text: string, value: string },
 *     onAdd: callback function when adding a filter,
 *     onDelete: callback function when deleting a filter
 *   }
 * @customAction (optional) React element
 * @loading (optional) boolean for whether the table is loading
 */
export default class TableActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchableActive: false,
      invalidFilters: false
    };
  }

  styles = {
    dropdownHeader: {
      color: '#868e96'
    },
    dropdownMenu: {
      overflow: 'auto'
    },
    labelItem: {
      display: 'flex',
      alignItems: 'center',

    }
  };

  renderDropdownItem = ({ item, keyPrefix, fn, sectionId }) => {
    const showSquareLabel = !sectionId.includes('status');
    return (
      <Dropdown.Item
        key={keyPrefix + item.id}
        style={{ display: 'flex' }}
        onClick={() => {
          fn({ key: sectionId === 'waitTime' ? 'waitTime' : sectionId === 'gates' ? 'concierges' : 'status', filterBy: item.id });
        }}
      >
        <ColoredCheckbox
          data-action={`select-${item.id}`}
          data-value={item['data-value']}
          label={<span style={{ display: 'flex', alignItems: 'center', color: item.color && !showSquareLabel ? item.color : '' }}>{item.color && showSquareLabel && <Icon className="custom-label-color-size" name="square" size="large" style={{ color: `#${item.color}`, fontWeight: 'bold' }} />} {item.title}</span>}
          checked={item.selected}
          style={{ display: 'flex', alignItems: 'center' }}
        />
      </Dropdown.Item>
    );
  }

  renderFiltersLabel = (item) => {
    const showSquareLabel = item.sectionId === 'waitTime';
    return (<Label
      size="large"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', margin: '0px 8px 8px 0px', color: item.color, borderRadius: '6px' }}
      key={`${item.sectionId}${item.id}`}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {showSquareLabel && <Icon className="custom-label-color-size" name="square" size="large" style={{ color: `#${item.color}`, fontWeight: 'bold' }} />}
        <p style={{ textalign: 'center' }}>{item.title}</p>
        <Icon
          name="delete"
          data-action="unselect-filter"
          style={{ alignself: 'flex-end', color: '#000000', marginLeft: '8px', cursor: 'pointer' }}
          onClick={() => {
            item.fn({ key: item.sectionId === 'waitTime' ? 'waitTime' : item.sectionId === 'gates' ? 'concierges' : 'status', filterBy: item.id });
          }}
        />
      </div>
    </Label>);
  }

  renderSimpleFilters = (extern = false) => {
    const {
      simpleFilters, loading
    } = this.props;
    return (
      <div style={{ display: 'flex', flexFlow: extern ? 'row wrap' : 'unset' }}>
        {simpleFilters && simpleFilters.map((filter, i) => {

          if (filter.notVisible) {
            return null;
          }

          let options = filter?.options?.filter((option) => !option.notVisible) || [];

          if (filter.multipleTags) {
            options = [...options.filter(o => !o.selected)];
          }
          if (filter.removeButton) {
            options = [...options, { text: filter.removeButtonText || `${__('Remove filters of')} ${filter.placeholder}`, value: 'clear-all', icon: 'trash' }];
          }

          const selected = filter.options && filter.options.map(i => i.selected && i.text).filter(i => i);
          return (
            <div style={{ marginTop: extern ? '10px' : '0px' }}>
              {filter.searchable ?
                <Dropdown
                  className={'dropdownFilter searchable'}
                  text={filter.multipleTags ? filter.placeholder : (selected.length > 0 ? selected[0] : filter.placeholder)}
                  disabled={loading}
                  icon={this.state.searchableActive ? 'search' : filter.icon}
                  fluid labeled search
                  style={{ ...filter.style, marginLeft: '5px' }}
                  placeholder={filter.placeholder}
                  options={options.map(option => ({ ...option, text: renderLongTextRaw(option.text) }))}
                  selectOnBlur={false}
                  onFocus={() => this.setState({ searchableActive: true })}
                  onClose={() => this.setState({ searchableActive: false })}
                  onChange={(e, { value }) => filter.fn({ filterBy: value, key: filter.key, multipleTags: filter.multipleTags })}
                  noResultsMessage={__('No results found')}
                />
                :
                filter.sections ?
                  <Menu
                    compact
                    style={{
                      height: '100%',
                      marginLeft: '5px',
                      border: '1px solid rgba(34,36,38,.15)',
                      borderRadius: '200px',
                      fontSize: 16
                    }}
                  >
                    <Dropdown
                      upward={false}
                      text={<span style={{ marginRight: '10px' }}>{__('Filters')}</span>}
                      data-action="search-message"
                      data-params="filters"
                      item
                      icon="chevron down"
                      style={{ padding: '10px 16px', borderRadius: '200px' }}
                    >
                      <Dropdown.Menu style={this.styles.dropdownMenu}>
                        {filter.sections.map((s, i) => [
                          <Dropdown.Header key={s.header + i} style={this.styles.dropdownHeader}>{s.header.toUpperCase()}</Dropdown.Header>,
                          ...s.items.map(item => this.renderDropdownItem({ item, keyPrefix: s.header + i, /* selectedIds: s.selectedIds, */ fn: filter.fn, sectionId: s.sectionId }))
                        ])}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                  :
                  filter.date ?
                    <Dropdown
                      upward={false}
                      ref={(c) => { this.dropdown = c; }}
                      inline
                      data-action={'open-more-settings'}
                      basic
                      text={<span style={{ fontWeight: 400, marginRight: 8 }}>{`${moment(filter.date.startDate).format('L').toString()}${filter.date.endDate ? ` - ${moment(filter.date.endDate).format('L')}` : ''}`}</span>}
                      style={{
                        height: '100%',
                        marginLeft: '5px',
                        border: '1px solid rgba(34,36,38,.15)',
                        borderRadius: '200px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 19px 0 16px',
                        minWidth: 243
                      }}
                      icon="chevron down"
                    >
                      <Dropdown.Menu onClick={e => e.stopPropagation()}>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          range={filter.date.range}
                          selectedStartDt={filter.date.startDate}
                          selectedEndDt={filter.date.endDate}
                          minDate={moment('2022-01-01')}
                          maxDate={moment()}
                          onSelect={({ selectedDt, selectedStartDt, selectedEndDt }) => {
                            filter.fn({
                              key: filter.key,
                              filterBy: filter.date.range ?
                                {
                                  startDate: moment(selectedStartDt).format('YYYY-MM-DDT00:00:00.000Z'),
                                  endDate: selectedEndDt ? moment(selectedEndDt).format('YYYY-MM-DDT00:00:00.000Z') : null
                                }
                                : {
                                  startDate: moment(selectedDt).format('YYYY-MM-DDT00:00:00.000Z'),
                                  endDate: moment(selectedDt).add(2, 'month').subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z')
                                }
                            });
                          }}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Dropdown
                      upward={false}
                      className={'dropdownFilter'}
                      text={filter.multipleTags ? filter.placeholder : (selected.length > 0 ? selected[0] : filter.placeholder)}
                      disabled={loading}
                      style={{ marginLeft: extern ? (i === 0 ? '0px' : '5px') : '5px' }}
                      icon={filter.icon}
                    >
                      <Dropdown.Menu>
                        {options.map(o => (
                          <Dropdown.Item
                            text={o.text}
                            icon={o.icon}
                            onClick={(_, value) => filter.fn({ filterBy: o.value, key: filter.key, multipleTags: filter.multipleTags })}
                            key={`simple-filter-option-${o.text}`}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
              }
            </div>
          );
        })}
      </div>
    );
  }

  renderMultiSelectFilters = () => {
    const { multiSelectFilters } = this.props;
    return (
      multiSelectFilters.map(f =>
        <MultiSelect
          name={f.name}
          search={f.search}
          onSearch={f.onSearch}
          loading={f.loading}
          options={f.options}
          optionsHeader={f.optionsHeader}
          onSelect={f.onSelect}
          onClear={f.onClear}
          triggerStyle={{ padding: '.85rem 1rem', marginTop: '10px', marginRight: '5px', border: '1px solid #e8e8e8' }}
          selectAll={f.selectAll}
          onSubmit={f.onSubmit}
          menuStyle={{ maxHeight: 400, width: 260 }}
        />
      )
    );
  }

  render() {
    const {
      search, order, multipleAutosuggest, filters, simpleFilters, customAction, menu, loading, customFilter, selectedMenu, externFilters, multiSelectFilters
    } = this.props;
    const tags = [];

    if (simpleFilters) {
      simpleFilters.forEach(({ options, key, onDelete, multipleTags }) => options && options.forEach(({ selected, value, text }) => {
        if (selected && multipleTags) {
          tags.push({
            text, value, key, onDelete
          });
        }
      }));
    }

    if (selectedMenu) {
      return (
        <div className="table-action-bar">
          <div className="actions">
            <div className="data-render-actions">
              {selectedMenu}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="table-action-bar">
        <div className="actions" style={{ alignItems: 'start' }}>
          <div className="data-render-actions" style={{ display: 'flex', width: multipleAutosuggest ? '100%' : null }}>
            {search && (
              <div className="searchMenu" style={{ marginRight: '12px', flexGrow: search.fillAllWidth ? 1 : 'unset' }}>
                <Icon className="search" />
                <input
                  className={`search${search.fillAllWidth ? ' fillAllWidth' : ''}`}
                  type="text"
                  placeholder={search.placeholder}
                  onChange={e => search.fn(e.target.value)}
                  value={search.value}
                />
              </div>
            )}
            {
              multipleAutosuggest && (
                <MultipleAutosuggest
                  location={multipleAutosuggest.location}
                  handleSelectedItems={multipleAutosuggest.fn}
                  requests={multipleAutosuggest.requests}
                  requestArguments={multipleAutosuggest.requestArguments}
                  apiExtract={[{ name: 'fullname' }]}
                  resultExtract={[{ edgeName: 'entities' }]}
                  style={[{ image: true, icon: 'user' }]}
                  placeholder={multipleAutosuggest.placeholder}
                  icon="at"
                  className="icon-left custom-label round-corners small-suggestion-avatars"
                  cssStyle={{
                    width: multipleAutosuggest.fillAllWidth ? null : '45%',
                    paddingTop: '2px'
                  }}
                  ids={multipleAutosuggest.value}
                />
              )
            }
            {order && (
              <div >
                <Dropdown
                  className={'dropdownFilter'}
                  text={order.value ? (order.value === 'Sem ordenação' ? 'Ordenar por' : order.value) : 'Ordenar por'}
                  disabled={loading}
                  style={{ marginLeft: '5px' }}
                  icon={'arrow down arrow up'}
                >
                  <Dropdown.Menu>
                    {order.options.map(o => (
                      <Dropdown.Item
                        text={o.text}
                        onClick={(_, value) => order.fn(value.text)}
                        key={`order-option-${o.text}`}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {!externFilters && this.renderSimpleFilters()}
            <div style={{ marginLeft: '5px' }}>
              {customFilter}
            </div>

          </div>
          {customAction}
          {menu}

        </div>
        <div style={{ display: 'flex', flexFlow: 'row wrap', marginTop: '15px' }}>
          {multiSelectFilters && this.renderMultiSelectFilters()}
          {externFilters && this.renderSimpleFilters(true)}
        </div>

        <div style={{ display: 'flex', marginTop: '14px', flexWrap: 'wrap' }}>
          {this.props && this.props.selectedFilters && this.props.selectedFilters.items.map(filter => this.renderFiltersLabel(filter))}
        </div>

        {
          tags.length > 0 && (
            <div className="filter-tags">
              {tags.map(tag => (
                <Tag
                  key={`tag-${tag.value}-filter-${tag.key}`}
                  name={tag.text}
                  loading={loading}
                  onDelete={tag.onDelete && (() => tag.onDelete(tag))}
                />
              ))}
            </div>
          )
        }
      </div >
    );
  }
}
