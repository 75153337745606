import React from 'react';
import { render } from '../../../../../../newRender';
import { ViewController } from '../ViewController';

describe('ViewController', () => {
  it('should render component when selectedMarkers props is undefined', async () => {
    const { queryByTestId } = render(<ViewController />);
    expect(queryByTestId('selected-item-1')).toBeNull();
  });

  it('should render viewController correctly with default props when it is not provided', () => {
    const { queryByTestId } = render(<ViewController />);
    expect(queryByTestId('selected-item-1')).toBeNull();
  });
});
