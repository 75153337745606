/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { fireEvent, render, waitFor } from '../../../../../../newRender';
import { ViewUI as UnitsDropdown } from '../ViewUI';

describe('UnitsDropdown', () => {
  let props;

  beforeEach(() => {
    props = {
      organizations: [],
      organizationOptions: [],
      setOrganizationOffset: jest.fn(),
      organizationLoading: false,
      handleOrganizationSearch: jest.fn(),
      organizationSearch: '',
      handleOrganizationChange: jest.fn(),
      organizationsTotalCount: 0,
      handleSelectAllChange: jest.fn(),
    };
  });

  it('should render component when selectedOrganizations props is undefined', () => {
    const { queryByTestId } = render(<UnitsDropdown {...props} />);
    expect(queryByTestId('selected-item-1')).toBeNull();
  });

  it('should test onChange when select a options in organization dropdown', async () => {
    props.organizationOptions = [
      {
        id: '1',
        content: 'Organization 1',
      },
    ];

    const { getByRole, container } = render(<UnitsDropdown {...props} />);

    const expandButton = await container.querySelector(
      '#dropdown-trigger > div:nth-child(2)',
    );

    fireEvent.click(expandButton);

    const organizationOption = await getByRole('button');

    await fireEvent.click(organizationOption);

    waitFor(() => {
      expect(props.handleOrganizationChange).toBeCalledTimes(1);
      expect(props.handleOrganizationChange).toBeCalledWith([]);
    });
  });
});
