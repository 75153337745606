import React from 'react';
import Form from '../../../../components/ui/Form';
import Avatar from '../../../../components/Avatar';

/**
 *
 * @param {{
 *  entity: {
 *   fullname: string,
 *    picture: {
 *      uri?: string,
 *    }
 * }
 * }} props
 */
export function EntityLabel({ entity }) {
  return (
    <Form.Field className="entity-label" style={{ marginRight: '16px' }}>
      <Avatar
        style={{
          height: '24px',
          width: '24px',
        }}
        avatar
        spaced="right"
        src={entity?.picture?.uri}
        alt={entity?.fullname}
      />
      <span>{entity?.fullname} </span>
    </Form.Field>
  );
}
