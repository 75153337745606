import React from 'react';
import { render } from '../../../../../newRender';

import { EntityLabel } from '../EntityLabel';

describe('<EntityLabel />', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();
    props = {
      entity: {
        fullname: 'Test User',
        picture: {
          uri: 'https://test.com/image.png',
        },
      },
    };
  });

  it('should render entity fullname when prop is provided', () => {
    const { getByText } = render(<EntityLabel {...props} />);

    expect(getByText(props.entity.fullname)).toBeInTheDocument();
  });

  it('should render entity picture when prop is provided', () => {
    const { queryByText } = render(<EntityLabel {...props} />);

    expect(queryByText('TU')).not.toBeInTheDocument();
  });

  it('should not render entity picture when prop is not provided', () => {
    props.entity.picture = null;
    const { queryByText } = render(<EntityLabel {...props} />);

    expect(queryByText('TU')).toBeInTheDocument();
  });
});
