import { getChildrenFromMarker } from '../../helpers/getChildrenFromMarker';

describe('getChildrenFromMarker', () => {
  it('should return empty when marker.markers is a empty array', () => {
    const marker = {
      id: 1,
      name: 'marker',
      markers: [],
    };
    const markersState = {
      markers: [
        {
          id: 1,
          children: [
            { id: 2, isSelected: true },
            { id: 3, isSelected: false },
          ],
        },
      ],
    };

    const result = getChildrenFromMarker(marker, markersState);

    expect(result).toEqual([]);
  });

  it('should return empty when marker.markers is null', () => {
    const marker = {
      id: 1,
      name: 'marker',
      markers: null,
    };
    const markersState = {
      markers: [
        {
          id: 1,
          children: [
            { id: 2, isSelected: true },
            { id: 3, isSelected: false },
          ],
        },
      ],
    };

    const result = getChildrenFromMarker(marker, markersState);

    expect(result).toEqual([]);
  });

  it('should return children from marker when it is provided', () => {
    const marker = {
      id: 1,
      name: 'marker',
      markers: [
        { id: 2, name: 'marker 2' },
        { id: 3, name: 'marker 3' },
      ],
    };
    const markersState = {
      markers: [2],
    };

    const result = getChildrenFromMarker(marker, markersState);

    expect(result).toEqual([
      { id: 2, content: 'marker 2', isSelected: true },
      { id: 3, content: 'marker 3', isSelected: false },
    ]);
  });

  it('should return children from marker when it is not provided', () => {
    const marker = {
      id: 1,
      name: 'marker',
      markers: [
        { id: 2, name: 'marker 2' },
        { id: 3, name: 'marker 3' },
      ],
    };
    const markersState = {
      markers: [
        {
          id: 1,
        },
      ],
    };

    const result = getChildrenFromMarker(marker, markersState);

    expect(result).toEqual([
      { id: 2, content: 'marker 2', isSelected: false },
      { id: 3, content: 'marker 3', isSelected: false },
    ]);
  });
});
