import { updateMarkersOptionsWithChildren } from '../../helpers/updateMarkersOptionsWithChildren';

describe('updateMarkersOptionsWithChildren', () => {
  it('should return the same options if no children are provided', () => {
    const options = [
      {
        id: '1',
        content: 'Child 1',
        isSelected: false,
        children: undefined,
      },
    ];

    const parent = {
      id: '1',
      content: 'Option 1',
      isSelected: true,
    };

    const updatedOptions = updateMarkersOptionsWithChildren(
      options,
      parent,
      undefined,
    );

    expect(updatedOptions).toStrictEqual([
      {
        children: undefined,
        content: 'Child 1',
        id: '1',
        isSelected: undefined,
      },
    ]);
  });

  it('should return the same options if the parent does not exist', () => {
    const options = [
      {
        id: '1',
        content: 'Child 1',
        isSelected: false,
        children: undefined,
      },
    ];

    const parent = {
      id: '2',
      content: 'Option 1',
      isSelected: true,
    };

    const updatedOptions = updateMarkersOptionsWithChildren(
      options,
      parent,
      options,
    );

    expect(updatedOptions).toStrictEqual([
      {
        children: undefined,
        content: 'Child 1',
        id: '1',
        isSelected: false,
      },
    ]);
  });

  it('should return when option id is equal parent id and every children is selected', () => {
    const options = [
      {
        id: '1',
        content: 'Child 1',
        isSelected: false,
        children: undefined,
      },
    ];

    const parent = {
      id: '1',
      content: 'Option 1',
      isSelected: true,
    };

    const updatedOptions = updateMarkersOptionsWithChildren(
      options,
      parent,
      options,
    );

    expect(updatedOptions).toStrictEqual([
      {
        children: [
          {
            children: undefined,
            content: 'Child 1',
            id: '1',
            isSelected: false,
          },
        ],
        content: 'Child 1',
        id: '1',
        isSelected: false,
      },
    ]);
  });
});
