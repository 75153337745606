import React from 'react';
import { render } from '../../../../../newRender';
import { ReceivedSection } from '../ReceivedSection';
import { __ } from '../../../../../i18n';
import { isNetwork } from '../../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { hasCommunicationStatusFeedback } from '../../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';

jest.mock(
  '../../../../../app/Domain/UseCases/Unleash/validations/IsNetwork',
  () => ({
    isNetwork: jest.fn(),
  }),
);

jest.mock(
  '../../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback',
  () => ({
    hasCommunicationStatusFeedback: jest.fn(),
  }),
);

describe('ReceivedSection', () => {
  let props;

  beforeEach(() => {
    jest.resetAllMocks();
    props = {
      message: {
        deleted: false,
        unread: false,
        entity: {
          fullname: 'Mr. Holmes',
        },
        statusSend: 'SENDING',
      },
      isNetworkChild: false,
      organization: 'some-organization',
    };
  });

  it('should render the LoaderMessage when statusSend is SENDING and isNetwork & hasCommunicationStatusFeedback are true', () => {
    isNetwork.mockReturnValue(true);
    hasCommunicationStatusFeedback.mockReturnValue(true);

    props.message.statusSend = 'SENDING';

    const { getByText } = render(<ReceivedSection {...props} />);
    const loaderMessage = getByText(__('Sending'));
    expect(loaderMessage).toBeInTheDocument();
  });

  it('should render the partial error message when statusSend is PARTIAL_ERROR and isNetwork & hasCommunicationStatusFeedback are true', () => {
    isNetwork.mockReturnValue(true);
    hasCommunicationStatusFeedback.mockReturnValue(true);

    props.message.statusSend = 'PARTIAL_ERROR';

    const { getByText } = render(<ReceivedSection {...props} />);
    const partialErrorMessage = getByText(
      __('Fail to send: some recipients did not receive.'),
    );
    expect(partialErrorMessage).toBeInTheDocument();
  });

  it('should render the error message when statusSend is ERROR and isNetwork & hasCommunicationStatusFeedback are true', () => {
    isNetwork.mockReturnValue(true);
    hasCommunicationStatusFeedback.mockReturnValue(true);

    props.message.statusSend = 'ERROR';

    const { getByText } = render(<ReceivedSection {...props} />);
    const errorMessage = getByText(__('Fail to send'));
    expect(errorMessage).toBeInTheDocument();
  });

  it('should not render LoaderMessage or error messages when isNetwork or hasCommunicationStatusFeedback are false', () => {
    isNetwork.mockReturnValue(false);
    hasCommunicationStatusFeedback.mockReturnValue(true);
    props.message.statusSend = 'SENDING';

    const { queryByText } = render(<ReceivedSection {...props} />);
    const loaderMessage = queryByText(__('Sending'));
    const errorMessage = queryByText(
      __('Fail to send: some recipients did not receive.'),
    );

    expect(loaderMessage).not.toBeInTheDocument();
    expect(errorMessage).not.toBeInTheDocument();
  });
});
