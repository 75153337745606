import React, { useEffect } from 'react';
import { ViewUI } from './ViewUI';
import { ViewModel as useViewModel } from './ViewModel';

/**
 * @typedef {{
 *  isDisabled: boolean;
 *  organizationId: string;
 *  entityId: string | number;
 *  markersState: import('@classapp-tech/edna').IMarkersState;
 *  setMarkersState: (state: import('@classapp-tech/edna').IMarkersState) => void;
 *  selectedMarkersIds: string[];
 * }} IViewControllerProps
 * @param {IViewControllerProps}
 */
export function ViewController({
  isDisabled,
  organizationId,
  entityId,
  markersState,
  setMarkersState,
  selectedMarkersIds = [],
}) {
  const {
    getMarkersByOrganizationId,
    markerOffset,
    markerSearch,
    setMarkerOffset,
    markersOptions,
    markerLoading,
    handleSelectAllChange,
    markersTotalCount,
    handleMarkerSearch,
    onChildrenChange,
    handleMarkerChange,
    setMarkersOptions,
    setMarkersSearch,
    setMarkersTotalCount,
  } = useViewModel({
    organizationId,
    entityId,
    setMarkersState,
    markersState,
    selectedMarkersIds,
  });

  useEffect(() => {
    setMarkerOffset(0);
    setMarkersOptions([]);
    setMarkersSearch('');
    setMarkersTotalCount(null);
  }, [isDisabled, organizationId, entityId]);

  useEffect(() => {
    if (selectedMarkersIds.length > 0)
      setMarkersState({ markers: selectedMarkersIds });
  }, [selectedMarkersIds]);

  useEffect(() => {
    if (organizationId && entityId && !isDisabled) {
      (async function () {
        await getMarkersByOrganizationId({
          organizationId,
          entityId,
          search: markerSearch,
          offset: markerOffset,
          limit: 10,
        });
      })();
    }
  }, [organizationId, entityId, markerOffset, isDisabled, markerSearch]);

  return (
    <ViewUI
      isDisabled={isDisabled}
      markers={markersState?.markers}
      markersOptions={markersOptions}
      handleMarkerChange={handleMarkerChange}
      handleMarkerSearch={handleMarkerSearch}
      setMarkerOffset={setMarkerOffset}
      markerLoading={markerLoading}
      markerSearch={markerSearch}
      markersTotalCount={markersTotalCount}
      handleSelectAllChange={handleSelectAllChange}
      onChildrenChange={onChildrenChange}
      isAllSelected={markersState?.canAccessAllMarkers}
      excludeMarkersLength={markersState?.excludeMarkers?.length ?? 0}
    />
  );
}
