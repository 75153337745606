import React, { useCallback, useState } from 'react';
import { debounce, uniqBy } from 'lodash';
import { getOrganizationsByNetworkIdUseCase } from '../../../../Domain/UseCases/Permissions/getOrganizationsByNetworkIdUseCase';
import { Option } from './components/Option';

const DEBOUNCE_IN_MILISECONDS = 1000;
const MAX_OPTIONS = 10;

/**
 * @param {number | string} organizationId
 * @param {number | string} entityId
 * @param {import ('../../hooks/useSelectRecipientModal').markersState} markersState
 * @param {(state: import ('../../hooks/useSelectRecipientModal').markersState) => void} setMarkersState
 * @param {React.MutableRefObject<ReturnType<typeof useMarkersState>>} markersStateRef
 */
export function ViewModel({
  organizationId,
  entityId,
  markersState,
  setMarkersState,
  markersStateRef,
}) {
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [organizationOffset, setOrganizationOffset] = useState(0);
  const [organizationSearch, setOrganizationSearch] = useState('');
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const [organizationsTotalCount, setOrganizationsTotalCount] = useState(null);
  const [loadedSelectedOrganizations, setLoadedSelectedOrganizations] =
    useState([]);

  const getOrganizationsByNetworkId = useCallback(
    async (
      /** @type {import('../../../../Data/Repository/Permissions/getOrganizationsByNetworkIdRepository').IGetOrganizationsByNetworkIdVariables} */
      variables,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
      if (
        organizationsTotalCount &&
        organizationOptions.length === organizationsTotalCount
      )
        return;

      setOrganizationLoading(true);

      const { data, errors } =
        await getOrganizationsByNetworkIdUseCase(variables);

      if (errors) {
        setOrganizationOptions([]);
        setOrganizationLoading(false);
        setOrganizationsTotalCount(0);
        return;
      }

      if (!organizationsTotalCount) {
        const totalCount = data?.node.organizations.totalCount ?? 0;
        setOrganizationsTotalCount(totalCount);
        setMarkersState({ organizationsTotalCount: totalCount });
      }

      const options =
        data?.node.organizations.nodes.map((organization) => ({
          id: organization.id,
          isSelected:
            markersStateRef?.current.organizations?.some(
              (org) => Number(org) === organization.id,
            ) ||
            (markersStateRef.current?.canAccessAllOrganizations &&
              !markersStateRef?.current.excludeOrganizations?.some(
                (org) => Number(org.id) === organization.id,
              )),
          content: <Option {...organization} />,
        })) ?? [];

      const distinctOptionsById = uniqBy(
        [...(organizationOptions ?? []), ...options],
        'id',
      );

      setOrganizationOptions(distinctOptionsById);
      setOrganizationLoading(false);
    },
    [markersStateRef, organizationsTotalCount, organizationOptions],
  );

  /**
   * @function updateOptionsFromGroups
   * @description Updates the given option with the isSelected field.
   * @param {OptionItem} option - The option to update.
   * @param {boolean} isSelected - The value of the isSelected field to update.
   * @returns {OptionItem} The updated option.
   */
  const updateOptionsFromGroups = (option, isSelected) => ({
    ...option,
    isSelected,
  });

  const organizationDebounce = useCallback(
    debounce(
      async (
        /** @type {string} */ search,
        /** @type {number | string} */ _organizationId,
        /** @type {number | string} */ _entityId,
      ) => {
        await getOrganizationsByNetworkId({
          organizationId: _organizationId,
          entityId: _entityId,
          search,
          offset: 0,
          limit: MAX_OPTIONS,
        });
        setOrganizationLoading(false);
      },
      DEBOUNCE_IN_MILISECONDS,
    ),
    [],
  );

  const handleSelectAllChange = useCallback(
    (/** @type {boolean} */ isSelected) => {
      if (isSelected) {
        setMarkersState({
          canAccessAllOrganizations: true,
          organizations: organizationOptions.map((option) => String(option.id)),
        });
        setOrganizationOptions((prev) =>
          prev.map((option) => ({
            ...option,
            isSelected: true,
          })),
        );
        return;
      }
      setMarkersState({
        canAccessAllOrganizations: false,
        organizations: [],
        excludeOrganizations: [],
      });

      setOrganizationOptions((prev) =>
        prev.map((option) => ({
          ...option,
          isSelected: false,
        })),
      );
    },
    [markersStateRef?.current, setOrganizationOptions, organizationOptions],
  );

  const handleOrganizationSearch = useCallback(
    (/* @type {string} */ search) => {
      if (!organizationId || !entityId || search === organizationSearch) return;
      setOrganizationSearch(search);
      setOrganizationLoading(true);
      setOrganizationOffset(0);
      organizationDebounce(search, organizationId, entityId);
    },
    [organizationId, entityId, organizationSearch],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleOrganizationChange = useCallback(
    (/** @type {boolean} */ isSelected, /** @type {OptionItem} */ option) => {
      if (organizationLoading) return;

      let value;
      if (isSelected) {
        value = [
          ...(markersStateRef?.current.organizations ?? []),
          String(option.id),
        ];
        if (markersStateRef?.current.canAccessAllOrganizations) {
          setMarkersState({
            excludeOrganizations:
              markersStateRef?.current.excludeOrganizations.filter(
                (item) => item.id !== option.id,
              ),
          });
        }
        setOrganizationOptions(
          organizationOptions.map((__option) => ({
            ...__option,
            isSelected: option.id === __option.id ? true : __option.isSelected,
          })),
        );
      } else {
        value = markersStateRef?.current.organizations.filter(
          (item) => Number(item) !== option.id,
        );
        if (markersStateRef?.current.canAccessAllOrganizations) {
          setMarkersState({
            excludeOrganizations: [
              ...(markersStateRef?.current.excludeOrganizations ?? []),
              option,
            ],
          });
        }
        setOrganizationOptions(
          organizationOptions.map((__option) => ({
            ...__option,
            isSelected: option.id === __option.id ? false : __option.isSelected,
          })),
        );
      }
      setMarkersState({ organizations: value });
      handleOrganizationSearch('');
    },
    [markersState, organizationOptions, organizationLoading],
  );

  return {
    getOrganizationsByNetworkId,
    organizationOptions,
    setOrganizationOptions,
    organizationOffset,
    setOrganizationOffset,
    organizationLoading,
    organizationSearch,
    setOrganizationSearch,
    setOrganizationLoading,
    organizationDebounce,
    organizationsTotalCount,
    setOrganizationsTotalCount,
    handleSelectAllChange,
    handleOrganizationSearch,
    handleOrganizationChange,
    updateOptionsFromGroups,
    loadedSelectedOrganizations,
    setLoadedSelectedOrganizations,
  };
}
