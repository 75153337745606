/**
 * @param {{
 *   defaultMarkers: string[],
 *   defaultOrganizations: string[]
 * }} params
 */
export function defaultMarkersState({
  defaultMarkers = [],
  defaultOrganizations = [],
}) {
  return {
    markers: defaultMarkers,
    excludeMarkers: [],
    canAccessAllMarkers: false,
    markersTotalCount: 0,
    organizations: defaultOrganizations,
    excludeOrganizations: [],
    canAccessAllOrganizations: false,
    organizationsTotalCount: 0,
  };
}
