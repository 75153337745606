import React from 'react';
import { render } from '../../../../../../newRender';
import { ViewController } from '../ViewController';
import * as ViewModel from '../ViewModel';

import * as useSelectRecipientModal from '../../../hooks/useSelectRecipientModal';

jest.mock('../ViewModel');

describe('ViewController', () => {
  let viewModelReturn;

  beforeEach(() => {
    jest.resetAllMocks();

    viewModelReturn = {
      getMarkersByOrganizationId: jest.fn().mockResolvedValue({
        data: {
          node: {
            markerCollections: {
              nodes: [
                {
                  id: 1,
                  name: 'Marker 1',
                  markers: [
                    {
                      id: 1,
                      name: 'Marker 1',
                    },
                  ],
                },
              ],
            },
          },
        },
      }),
      getOrganizationsByNetworkId: jest.fn().mockResolvedValue({
        data: {
          node: {
            organizations: {
              nodes: [
                {
                  id: 1,
                  name: 'Organization 1',
                },
              ],
            },
          },
        },
      }),
      selectedMarkersLength: 0,
      selectedOrganizationsLength: 0,
      setMarkers: jest.fn(),
      setOrganizations: jest.fn(),
      markers: [],
      organizations: [],
      markersOptions: [
        {
          id: 1,
          name: 'Marker 1',
        },
      ],
      organizationOptions: [
        {
          id: 1,
          content: 'Organization 1',
        },
      ],
      setMarkersOptions: jest.fn(),
      setOrganizationOptions: jest.fn(),
      markerOffset: 0,
      organizationOffset: 0,
      setMarkerOffset: jest.fn(),
      setOrganizationOffset: jest.fn(),
      setOrganizationSearch: jest.fn(),
      setOrganizationLoading: jest.fn(),
      organizationDebounce: jest.fn(),
      setMarkersSearch: jest.fn(),
      setMarkerLoading: jest.fn(),
      markerDebounce: jest.fn(),
      setCanAccessAllOrganizationsSelected: jest.fn(),
      organizationSearch: '',
      loadedSelectedOrganizations: [],
      setLoadedSelectedOrganizations: jest.fn(),
    };

    jest
      .spyOn(ViewModel, 'ViewModel')
      .mockImplementation(() => viewModelReturn);

    jest
      .spyOn(useSelectRecipientModal, 'useSelectRecipientModal')
      .mockReturnValue({
        markersState: {
          organizations: [],
          canAccessAllOrganizations: false,
          markers: [],
          canAccessAllMarkers: false,
          setMarkers: jest.fn(),
          setOrganizations: jest.fn(),
          setCanAccessAllMarkers: jest.fn(),
          setCanAccessAllOrganizations: jest.fn(),
          excludeMarkers: [],
          excludeOrganizations: [],
          setExcludeMarkers: jest.fn(),
          setExcludeOrganizations: jest.fn(),
          markersTotalCount: 0,
          setMarkersTotalCount: jest.fn(),
          organizationsTotalCount: 0,
          setOrganizationsTotalCount: jest.fn(),
        },
        groupsState: {
          groups: [],
          isAllGroups: false,
          organization: null,
          setGroups: jest.fn(),
          setIsAllGroups: jest.fn(),
          excludeGroups: [],
          setOrganization: jest.fn(),
          setExcludeGroups: jest.fn(),
          groupsTotalCount: 0,
          setGroupsTotalCount: jest.fn(),
        },
        entitiesState: {
          entities: [],
          organization: null,
          setEntities: jest.fn(),
          setOrganization: jest.fn(),
        },
      });
  });

  it('should render viewController correctly', () => {
    const container = render(
      <ViewController organizationId={1} entityId={1} />,
    );

    expect(container).toBeDefined();
  });

  it('should call getOrganizationsByOrganizationId when organizationId is defined', () => {
    render(<ViewController organizationId={1} entityId={1} />);

    expect(viewModelReturn.getOrganizationsByNetworkId).toHaveBeenCalled();
  });

  it('should not call getMarkersByOrganizationId when organizationId is undefined', () => {
    render(<ViewController organizationId={undefined} entityId={undefined} />);

    expect(viewModelReturn.getMarkersByOrganizationId).not.toHaveBeenCalled();
  });

  it('should not call getOrganizationsByOrganizationId when organizationId is undefined', () => {
    render(<ViewController organizationId={undefined} entityId={undefined} />);

    expect(viewModelReturn.getOrganizationsByNetworkId).not.toHaveBeenCalled();
  });
});
