/**
 * @param {import('@classapp-tech/edna').OptionItem[]} options
 * @param {boolean} isSelected
 */

export function setAllChildrenSelected(options, isSelected) {
  return options.map((option) => ({
    ...option,
    isSelected,
    children: option.children?.map((child) => ({
      ...child,
      isSelected,
    })),
  }));
}
