import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;

  div {
    max-height: 15rem !important;
  }
`;

export const InputLabel = styled.span`
  font-family:
    Circular Std,
    sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.blackboard.main};
  margin-bottom: 0.5rem;
`;
