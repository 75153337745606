import { defaultMarkersState } from '../defaultMarkersState';

describe('defaultMarkersState', () => {
  it('should return default markers state', () => {
    expect(
      defaultMarkersState({
        defaultMarkers: [],
        defaultOrganizations: [],
      }),
    ).toStrictEqual({
      markers: [],
      excludeMarkers: [],
      canAccessAllMarkers: false,
      markersTotalCount: 0,
      organizations: [],
      excludeOrganizations: [],
      canAccessAllOrganizations: false,
      organizationsTotalCount: 0,
    });
  });
});
