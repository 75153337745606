import React from 'react';
import { LoaderMessage } from '../LoaderMessage';
import { render } from '../../../../../newRender';

describe('LoaderMessage', () => {
  it('should render the component', () => {
    const { container } = render(<LoaderMessage />);
    expect(container).toBeDefined();
  });

  it('should return null if statusSend is DONE, ERROR or PARTIAL_ERROR', () => {
    const { container } = render(
      <LoaderMessage statusSend="DONE" hasCommunicationStatusFeedback />,
    );
    expect(container).toBeEmptyDOMElement();
  });

  it('should return loader if statusSend is not DONE, ERROR or PARTIAL_ERROR', () => {
    const { container } = render(
      <LoaderMessage statusSend="IN_PROGRESS" hasCommunicationStatusFeedback />,
    );
    expect(container).toBeDefined();
  });
});
