import { updateMarkersWithChildren } from '../../helpers/updateMarkersWithChildren';

describe('updateMarkersWithChildren', () => {
  it('should update markers with children', () => {
    const markers = ['1', '2'];
    const option = {
      id: '1',
      content: 'marker 1',
      isSelected: false,
    };

    const result = updateMarkersWithChildren(markers, option);

    expect(result).toEqual(['2']);
  });

  it('should update markers with children when parent is not selected', () => {
    const markers = ['1', '2'];
    const option = {
      id: '1',
      content: 'marker 1',
      isSelected: false,
    };

    const result = updateMarkersWithChildren(markers, option);

    expect(result).toEqual(['2']);
  });
});
