import React from 'react';
import { render } from '../../../../../newRender';

import { PersonalizedFile } from '../PersonalizedFile';

describe('<PersonalizedFile />', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      env: 'local',
      onOpenRecipientModal: jest.fn(),
    };
  });

  it('should render component when env is provided', () => {
    const { container } = render(<PersonalizedFile {...props} />);

    expect(container).not.toBeEmpty();
  });

  it('should render component with default env when prop is not provided', () => {
    const { container } = render(<PersonalizedFile />);

    expect(container).not.toBeEmpty();
  });
});
