import React from 'react';

import { InputLabel, InputContainer } from './ViewStyle';
import { MultipleDropdown } from '@classapp-tech/edna';

import { __ } from '../../../../../i18n';

/**
 * @typedef {{
 *  markers: import('@classapp-tech/edna').OptionItem[],
 *  markersOptions: import('@classapp-tech/edna').OptionItem[],
 *  markerLoading: boolean,
 *  handleMarkerSearch: (value: string) => void,
 *  markerSearch: string,
 *  handleMarkerChange: (value: string) => void,
 *  isDisabled: boolean,
 *  handleSelectAllChange: (isSelect: boolean, option: import('@classapp-tech/edna').OptionItem) => void,
 *  isAllSelected: boolean,
 *  setIsAllSelected: React.Dispatch<React.SetStateAction<boolean>>,
 *  markersTotalCount: number | null,
 *  onChildrenChange: (isSelect: boolean, option: import('@classapp-tech/edna').OptionItem, parent: import('@classapp-tech/edna').ITOptionProps) => void,
 * }}
 * @param {ViewUIProps}
 */

export function ViewUI({
  markersOptions = [],
  markerLoading,
  handleMarkerSearch,
  markerSearch,
  handleMarkerChange,
  isDisabled,
  isAllSelected,
  markersTotalCount,
  handleSelectAllChange,
  onChildrenChange,
  excludeMarkersLength,
}) {
  return (
    <InputContainer style={{ marginTop: '1rem' }}>
      <InputLabel>{__('Select the markers')}*:</InputLabel>
      <MultipleDropdown.Marker
        isLoading={markerLoading}
        options={markersOptions}
        placeholder={__('Select one or more markers')}
        searchText={markerSearch}
        setSearchText={handleMarkerSearch}
        notFoundText={__('No result found')}
        selectedOptionsText={__('selected (plural)')}
        selectAllComponent={<div>{__('Select all')}</div>}
        hasCheckbox
        isDisabled={isDisabled}
        totalOfOptions={markersTotalCount ?? 0}
        onSelectAll={handleSelectAllChange}
        isAllSelected={isAllSelected}
        onOptionChange={handleMarkerChange}
        onChildrenChange={onChildrenChange}
        excludedOptionsLength={excludeMarkersLength}
      />
    </InputContainer>
  );
}
