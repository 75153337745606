import React, { useEffect } from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';
import PubSub from 'pubsub-js';

import { createUrlWithHash } from '../../../../utils';
import { Loading } from '@classapp-tech/edna';

import config from '../../../../../../config/server.config';

/**
 * @param {{
 *  env: 'local' | 'development' | 'stage' | 'internal' | 'production',
 *  onOpenRecipientModal: () => void,
 *  hasAttachments: boolean,
 *  recipients: any,
 *  openPersonalizedMediaModal: boolean,
 *  closePersonalizedMediaModal: () => void
 * }}
 */
export function PersonalizedFile({
  env = 'local',
  onOpenRecipientModal,
  hasAttachments,
  recipients,
  closePersonalizedMediaModal,
  organizationId,
  openPersonalizedMediaModal = false,
}) {
  useEffect(() => {
    const token = PubSub.subscribeOnce('MICROFRONTEND_READY', (_, mcfName) => {
      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {
        hasAttachments,
        onOpenRecipientModal,
      });
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  useEffect(() => {
    PubSub.publish('SULLY_MICROFRONTEND_DATA', {
      hasAttachments,
      onOpenRecipientModal,
      recipients,
      openPersonalizedMediaModal,
      closePersonalizedMediaModal,
      organizationId,
    });
  }, [hasAttachments, recipients, openPersonalizedMediaModal, organizationId]);

  return (
    <RemoteMountedComponent
      url={createUrlWithHash(
        `${config.microfrontends.sully[env]}/remoteEntry.js`,
      )}
      name="sully"
      noStyle
      moduleName="./PersonalizedFile"
      fallbackComponent={null}
      loadingComponent={<Loading variation="basic" size={20} />}
    />
  );
}
