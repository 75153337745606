/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { render } from '../../../../../../newRender';
import { ViewUI as MarkersDropdown } from '../ViewUI';

describe('MarkersDropdown', () => {
  let props;

  beforeEach(() => {
    props = {
      markers: [],
      markersOptions: [],
      setMarkerOffset: jest.fn(),
      markerLoading: false,
      handleMarkerSearch: jest.fn(),
      markerSearch: '',
      handleMarkerChange: jest.fn(),
      markersTotalCount: 0,
      handleSelectAllChange: jest.fn(),
    };
  });

  it('should render component when selectedMarkers props is undefined', () => {
    const { queryByTestId } = render(<MarkersDropdown {...props} />);
    expect(queryByTestId('selected-item-1')).toBeNull();
  });
});
