import { getChildrenFromMarker } from './getChildrenFromMarker';

/**
 * @param {import('../../../hooks/useMarkersState').markersState} markersState
 * @param {import('./getChildrenFromMarker').marker[]} markers
 */

export function getOptions(markers, markersState) {
  if (!markers.length) return [];

  return markers.map((marker) => {
    if (!marker.markers?.length) {
      return {
        id: marker.id,
        content: marker.name,
        isSelected:
          markersState.canAccessAllMarkers ||
          markersState.markers.some((item) => item === marker.id),
      };
    }

    const children = getChildrenFromMarker(marker, markersState);

    return {
      id: marker.id,
      content: marker.name,
      isSelected:
        markersState.canAccessAllMarkers ||
        children.every((child) => child.isSelected),
      children,
    };
  });
}
