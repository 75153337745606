/* eslint-disable indent */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { hasCommunicationStatusFeedback } from '../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';
import Avatar from '../../../../components/Avatar';

const styles = {
  avatar: {
    borderRadius: '50%',
  },
  corner: {
    position: 'absolute',
    bottom: '-4px',
    right: '-4px',
    backgroundColor: '#ffffff',
    borderRadius: '1em',
  },
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '36px',
    height: '36px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

/**
 * @typedef {{
 *   unread: boolean,
 *   deleted: boolean,
 *   statusSend: string,
 *   organization: string,
 *   recipientsCount: number,
 *   toEntity: {
 *     fullname: string,
 *     picture: {
 *       uri: string,
 *     }
 *   },
 * }} MessageItem
 *
 * @typedef {{
 *   message: MessageItem,
 *   isOrganizationPage: boolean,
 * }} Props
 * @param {Props} props
 * @returns {React.JSX.Element}
 */

export function AvatarReceived({ message, organization, isOrganizationPage }) {
  if (isNetwork(organization) && hasCommunicationStatusFeedback(organization)) {
    return (
      <div style={{ width: '48px', height: '48px' }}>
        <Avatar
          avatar
          src={
            message.entity &&
            message.entity.picture &&
            message.entity.picture.uri
          }
          alt={message.entity.fullname}
          style={{
            width: '100%',
            height: '100%',
            opacity: message.deleted ? 0.65 : 1,
            ...(message.statusSend !== 'DONE' && message.statusSend !== null
              ? {
                  width: '36px',
                  height: '36px',
                }
              : {}),
          }}
        />
        {message.statusSend !== 'DONE' && message.statusSend !== null ? (
          <div style={styles.moreRecipients} className="moreRecipients">
            ...
          </div>
        ) : null}
        {!isOrganizationPage &&
          (message.unread !== null ||
            (message.unread === null &&
              message.notifications &&
              message.notifications.totalCount > 0)) && (
            <div style={styles.corner}>
              <Icon
                name="circle"
                style={{ margin: 0, color: '#0080ff' }}
                className="bold"
              />
            </div>
          )}
      </div>
    );
  }

  return (
    <div style={{ width: '48px', height: '48px' }}>
      <Avatar
        avatar
        src={
          message.entity && message.entity.picture && message.entity.picture.uri
        }
        alt={message.entity.fullname}
        style={{
          width: '100%',
          height: '100%',
          opacity: message.deleted ? 0.65 : 1,
        }}
      />
      {!isOrganizationPage &&
        (message.unread !== null ||
          (message.unread === null &&
            message.notifications &&
            message.notifications.totalCount > 0)) && (
          <div style={styles.corner}>
            <Icon
              name="circle"
              style={{ margin: 0, color: '#0080ff' }}
              className="bold"
            />
          </div>
        )}
    </div>
  );
}
