import { updateOptionSelection } from '../../helpers/updateOptionSelection';

describe('updateOptionSelection', () => {
  it('should update the option selection', () => {
    const options = [
      {
        id: '1',
        content: 'Option 1',
        isSelected: false,
        children: [
          {
            id: '1-1',
            content: 'Option - Child 1',
            isSelected: false,
          },
        ],
      },
      {
        id: '2',
        isSelected: false,
        content: 'Option 2',
        children: [
          {
            id: '2-1',
            content: 'Option - Child 2',
            isSelected: false,
          },
        ],
      },
    ];
    const option = {
      id: '1',
      content: 'Option 1',
      isSelected: true,
    };
    const isSelected = true;

    const result = updateOptionSelection(options, option, isSelected);

    expect(result).toEqual([
      {
        id: '1',
        isSelected: true,
        content: 'Option 1',
        children: [
          {
            id: '1-1',
            content: 'Option - Child 1',
            isSelected: true,
          },
        ],
      },
      {
        id: '2',
        isSelected: false,
        content: 'Option 2',
        children: [
          {
            id: '2-1',
            content: 'Option - Child 2',
            isSelected: false,
          },
        ],
      },
    ]);
  });

  it('should return empty when options has not children', () => {
    const options = [
      {
        id: '1',
        content: 'Option 1',
        isSelected: false,
        children: undefined,
      },
    ];
    const option = {
      id: '1',
      content: 'Option 1',
      isSelected: true,
    };
    const isSelected = true;

    const result = updateOptionSelection(options, option, isSelected);

    expect(result).toEqual([
      {
        children: undefined,
        content: 'Option 1',
        id: '1',
        isSelected: true,
      },
    ]);
  });
});
