import React from 'react';
import { Container } from '.';
import { render } from '../../../../../newRender';
import { SelectRecipientModalProvider } from '../../hooks/useSelectRecipientModal';

describe('Container', () => {
  const props = {
    setMarkersState: jest.fn(),
    markersState: {
      organizations: [],
      markers: [],
      canAccessAllOrganizations: false,
      canAccessAllMarkers: false,
      excludeOrganizations: [],
      excludeMarkers: [],
      markersTotalCount: 0,
      organizationsTotalCount: 0,
    },
  };
  it('should render Container component', () => {
    const { getByText } = render(
      <SelectRecipientModalProvider {...props}>
        <Container />
      </SelectRecipientModalProvider>,
    );
    expect(getByText('Permissions')).toBeInTheDocument();
  });
});
