/**
 * @param {import('@classapp-tech/edna').OptionItem[] | undefined} children
 * @param {import('@classapp-tech/edna').OptionItem} option
 * @param {boolean} isSelected
 */

export function updateChildrenSelectionByParent(children, option, isSelected) {
  return children?.map((child) => ({
    ...child,
    isSelected: child.id === option.id ? isSelected : child.isSelected,
  }));
}
