import { useCallback, useState } from 'react';
import { debounce, uniqBy } from 'lodash';
import { updateChildrenSelectionByParent } from './helpers/updateChildrenSelectionByParent';
import { updateMarkersOptionsWithChildren } from './helpers/updateMarkersOptionsWithChildren';
import { updateMarkersWithChildren } from './helpers/updateMarkersWithChildren';
import { updateOptionSelection } from './helpers/updateOptionSelection';
import { setAllChildrenSelected } from './helpers/setAllChildrenSelected';
import { getMarkers } from './helpers/getMarkers';
import { getOptions } from './helpers/getOptions';
import { getMarkersByOrganizationIdUseCase } from '../../../../Domain/UseCases/Permissions/GetMarkersByOrganizationId';

const DEBOUNCE_IN_MILISECONDS = 1000;
const MAX_OPTIONS = 10;

/**
 * @param { number | string } organizationId
 * @param { number | string } entityId
 * @param { import('@classapp-tech/edna').IMarkersState } markersState
 * @param { (state: import('@classapp-tech/edna').IMarkersState) => void } setMarkersState
 * @param { string[] } selectedMarkersIds
 */

export function ViewModel({
  organizationId,
  entityId,
  markersState,
  setMarkersState,
  selectedMarkersIds,
}) {
  const [markersOptions, setMarkersOptions] = useState([]);
  const [markerOffset, setMarkerOffset] = useState(0);
  const [markerSearch, setMarkersSearch] = useState('');
  const [markerLoading, setMarkerLoading] = useState(false);
  const [, setCanAccessAllMarkersSelected] = useState(false);
  const [markersTotalCount, setMarkersTotalCount] = useState(null);

  const getMarkersByOrganizationId = useCallback(
    async (params) => {
      setMarkerLoading(true);
      const { data, errors } = await getMarkersByOrganizationIdUseCase(params);

      if (errors) {
        setMarkersOptions([]);
        setMarkerLoading(false);
        setMarkersTotalCount(0);
        return;
      }

      if (!markersTotalCount) {
        const totalCount = data?.node.markers?.totalCount ?? 0;
        setMarkersTotalCount(totalCount);
        setMarkersState({ markersTotalCount: totalCount });
      }

      const options = getOptions(
        data?.node.markerCollections.nodes ?? [],
        markersState,
        selectedMarkersIds,
      );

      if (params.offset === 0) {
        setMarkersOptions(options);
        if (markersState.canAccessAllMarkers) {
          setMarkersState({ markers: options });
        }
        setMarkerLoading(false);
        return;
      }

      setMarkersOptions((prev) => uniqBy([...prev, ...options], 'id'));
      setMarkerLoading(false);
    },
    [
      markersState,
      markersTotalCount,
      markersOptions,
      getMarkersByOrganizationIdUseCase,
      setMarkersOptions,
    ],
  );

  const markerDebounce = useCallback(
    debounce(async (search, __organizationId, __entity) => {
      await getMarkersByOrganizationId({
        organizationId: __organizationId,
        entityId: __entity,
        search,
        offset: 0,
        limit: MAX_OPTIONS,
      });
      setMarkerLoading(false);
    }, DEBOUNCE_IN_MILISECONDS),
    [getMarkersByOrganizationId],
  );

  const handleSelectAllChange = useCallback(
    (isSelected) => {
      const shouldSelectAll =
        !markersState?.canAccessAllMarkers &&
        (isSelected || markersState?.markers?.length > 0);

      if (shouldSelectAll) {
        setMarkersState({
          canAccessAllMarkers: true,
          markers: setAllChildrenSelected(markersOptions, true),
          excludeMarkers: [],
        });

        setMarkersOptions((prev) => setAllChildrenSelected(prev, true));

        return;
      }

      setMarkersState({
        canAccessAllMarkers: false,
        markers: [],
        excludeMarkers: [],
      });

      setMarkersOptions((prev) => setAllChildrenSelected(prev, false));
    },
    [markersState, markersOptions],
  );

  const handleMarkerSearch = useCallback(
    (search) => {
      if (!organizationId || !entityId || search === markerSearch) return;
      setMarkersSearch(search);
      setMarkerLoading(true);
      setMarkerOffset(0);
      markerDebounce(search, organizationId, entityId);
    },
    [organizationId, entityId, markerSearch, markerDebounce],
  );

  const handleMarkerChange = useCallback(
    (isSelected, option) => {
      const markers = getMarkers(isSelected, option, markersState);

      if (!markers?.length) {
        setMarkersState({
          canAccessAllMarkers: false,
          excludeMarkers: [],
        });
      }

      setMarkersOptions((prev) =>
        updateOptionSelection(prev, option, isSelected),
      );
      const children = option.children ?? [];

      if (!isSelected) {
        const excludeMarkers = [...markersState.excludeMarkers];
        children.forEach((child) => {
          const index = markersState.excludeMarkers.findIndex(
            (item) => item.id === child.id,
          );
          if (index === -1) excludeMarkers.push(child);
        });
        setMarkersState({ excludeMarkers });
      } else {
        let excludeMarkers = [...markersState.excludeMarkers];
        children.forEach((child) => {
          const excludeMarker = markersState.excludeMarkers.find(
            (item) => item.id === child.id,
          );
          if (excludeMarker) {
            excludeMarkers = excludeMarkers.filter(
              (item) => item.id !== child.id,
            );
          }
        });
        setMarkersState({ excludeMarkers });
      }

      setMarkersState({ markers });
      setMarkersSearch('');
    },
    [markersState?.markers, markersOptions, markersState?.canAccessAllMarkers],
  );

  const onChildrenChange = useCallback(
    (isSelected, option, parent) => {
      const updatedChildren = updateChildrenSelectionByParent(
        parent.children,
        option,
        isSelected,
      );
      setMarkersSearch('');

      const excludeMarkers = [...markersState.excludeMarkers];

      const isAlreadyRemoved = markersState.excludeMarkers.some(
        (item) => item.id === option.id,
      );

      if (markersState.canAccessAllMarkers) {
        if (!isAlreadyRemoved && !isSelected) {
          excludeMarkers.push(option);
        } else if (isAlreadyRemoved && isSelected) {
          const index = markersState.excludeMarkers.findIndex(
            (item) => item.id === option.id,
          );
          excludeMarkers.splice(index, 1);
        }

        setMarkersState({ excludeMarkers });
      }

      if (!markersState.markers.some((item) => item === option.id)) {
        setMarkersState({
          markers: [...markersState.markers, String(option.id)],
        });
      } else {
        // eslint-disable-next-line max-len
        const updatedMarkers = updateMarkersWithChildren(
          markersState.markers,
          option,
        );

        setMarkersState({ markers: updatedMarkers });

        if (!updatedMarkers.length) {
          setMarkersState({
            canAccessAllMarkers: false,
            excludeMarkers: [],
          });
        }

        if (updatedChildren?.every((child) => !child.isSelected)) {
          setMarkersState({
            markers: markersState.markers.filter((item) => item !== option.id),
          });
        }
      }

      setMarkersOptions((prev) =>
        updateMarkersOptionsWithChildren(prev, parent, updatedChildren),
      );
    },
    [markersState, markersOptions],
  );

  return {
    getMarkersByOrganizationId,
    markerOffset,
    markerSearch,
    setCanAccessAllMarkersSelected,
    setMarkersSearch,
    setMarkerLoading,
    setMarkerOffset,
    markerDebounce,
    markersOptions,
    markerLoading,
    setMarkersOptions,
    setMarkersTotalCount,
    handleSelectAllChange,
    markersTotalCount,
    handleMarkerSearch,
    onChildrenChange,
    handleMarkerChange,
  };
}
