/* eslint-disable prettier/prettier */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import Avatar from '../../../../components/Avatar';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { hasCommunicationStatusFeedback } from '../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';

const styles = {
  avatar: {
    borderRadius: '50%',
  },
  corner: {
    position: 'absolute',
    bottom: '-4px',
    right: '-4px',
    backgroundColor: '#ffffff',
    borderRadius: '1em',
  },
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '36px',
    height: '36px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

/**
 * @typedef {{
 *   recipientsCount: number,
 *   unread: boolean,
 *   deleted: boolean,
 *   statusSend: string,
 *   organization: string,
 *   toEntity: {
 *     fullname: string,
 *     status: number,
 *     picture: {
 *       uri: string,
 *     }
 *   },
 *   notifications: {
 *    totalCount: number,
 *   },
 * }} MessageItem
 *
 * @typedef {{
 *   message: MessageItem,
 * }} Props
 * @param {Props} props
 * @returns {React.JSX.Element}
 */

export function AvatarSended({ message, organization }) {
  if(isNetwork(organization) && hasCommunicationStatusFeedback(organization)) {
    return (
      <div>
        <Avatar
          className="avatar"
          src={
            message.toEntity &&
            message.toEntity.picture &&
            message.toEntity.picture.uri
          }
          alt={message.toEntity && message.toEntity.fullname}
          style={{
            width: message.recipientsCount > 1 || message.statusSend !== 'DONE' && message.statusSend !== null ? '36px': '48px',
            height: message.recipientsCount > 1 || message.statusSend !== 'DONE' && message.statusSend !== null ? '36px': '48px',
            opacity: message.deleted ? 0.65 : 1 
          }}
        />
        {
          message.statusSend !== 'DONE' && message.statusSend !== null ? (
            <div style={styles.moreRecipients}>...</div>
          ) : 
            message.recipientsCount > 1 ? (
              <div style={styles.moreRecipients}>+{message.recipientsCount - 1}</div>
            ) : null  
        }
        {(message.unread !== null || (message.unread === null && (message.notifications && message.notifications.totalCount > 0))) && (
          <div style={styles.corner}>
            <Icon
              name="circle"
              style={{ margin: 0, color: '#0080ff' }}
              className="bold"
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Avatar
        className="avatar"
        src={
          message.toEntity &&
          message.toEntity.picture &&
          message.toEntity.picture.uri
        }
        alt={message.toEntity && message.toEntity.fullname}
        style={message.deleted
          ? message.recipientsCount > 1
            ? { width: '36px', height: '36px', opacity: 0.65 }
            : { width: '48px', height: '48px', opacity: 0.65 }
          : message.recipientsCount > 1
            ? { width: '36px', height: '36px' }
            : { width: '48px', height: '48px' }
        }
      />
      {message.recipientsCount > 1 ? (
        <div style={styles.moreRecipients}>+{message.recipientsCount - 1}</div>
      ) : null}
      {(message.unread !== null || (message.unread === null && (message.notifications && message.notifications.totalCount > 0))) && (
        <div style={styles.corner}>
          <Icon
            name="circle"
            style={{ margin: 0, color: '#0080ff' }}
            className="bold"
          />
        </div>
      )}
    </div>
  );
}