import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Button } from 'semantic-ui-react';
import { __ } from '../i18n';
import parse from '../lib/parse';
import Modal from './Modal';
import { headers, data } from '../lib/sample';

@inject('store') @observer
export default class SampleData extends React.Component {
  render() {
    const featureFlag = this.props.store.currentOrganization?.unleashStatus?.new_student_flow
    const { store, entityType } = this.props;
    const { currentOrganization } = store;

    return (
      <Modal open closeIcon="times close" size="large" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header />
        <Modal.Content style={{ overflow: 'scroll' }}>
          <Table celled size="small">
            <Table.Header>
              <Table.Row>
                {
                  headers(currentOrganization.type, entityType, featureFlag).map((header, i) =>
                    <Table.HeaderCell key={i}>{header}</Table.HeaderCell>
                  )
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                data(currentOrganization.type, entityType, featureFlag).map((row, i) =>
                  <Table.Row key={i}>
                    {
                      row.map((cell, j) => <Table.Cell key={j}>{cell}</Table.Cell>)
                    }
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="cloud download"
            content={__('Download sample')}
            as="a"
            href={parse.makeFile('text/csv', headers(currentOrganization.type, entityType, featureFlag), data(currentOrganization.type, entityType, featureFlag))}
            download={__('Spreadsheet Sample') + '.csv'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
