import React from 'react';
import { Menu, Loader } from 'semantic-ui-react';

/**
 * Params of PagePaginator
 * @param {number} index
 * @param {number} currentPage
 * @param {number} limit
 * @param {Boolean} loading
 * @param {Function} onClick
 * @return {React.Element}
 */

export function PagePaginator({ index, currentPage, limit, loading, onClick }) {
  return (
    <Menu.Item
      data-action="load-more"
      data-params={index}
      key={index}
      style={
        currentPage === index
          ? { color: '#084FFF', backgroundColor: '#F2F7FF' }
          : {}
      }
      active={currentPage === index}
      onClick={() => onClick('p', index, limit)}
    >
      {index === currentPage && loading ? <Loader active /> : index}
    </Menu.Item>
  );
}
